const actions = {
  SET_IS_LOGIN: "SET_IS_LOGIN",
  SET_FISRT_NAME: "SET_FISRT_NAME",
  SET_LAST_NAME: "SET_LAST_NAME",
  SET_USER_TOCKEN: "SET_USER_TOCKEN",
  SET_BUSSINESS_NAME: "SET_BUSSINESS_NAME",
  SET_SIGNUP_NAME: "SET_SIGNUP_NAME",
  SET_POST_SIGNUP: "SET_POST_SIGNUP",
  SET_USER_DATA: "SET_USER_DATA",
  SET_COMPANY_ID: "SET_COMPANY_ID",
  SET_FINANCIAL_YEAR: "SET_FINANCIAL_YEAR",
  IS_LOADING: "IS_LOADING",
  IS_RESET: "IS_RESET",
  IS_DOWNLOADING: "IS_DOWNLOADING",
  IS_COMPANY_ADDED: "IS_COMPANY_ADDED",
  ADD_COMPANY_EMAIL: "ADD_COMPANY_EMAIL",

  SetData: (data: any) => {
    return {
      type: actions.SET_IS_LOGIN,
      data,
    };
  },
  SetfirstName: (data: any) => {
    return {
      type: actions.SET_FISRT_NAME,
      data,
    };
  },
  SetsignupName: (data: any) => {
    return {
      type: actions.SET_SIGNUP_NAME,
      data,
    };
  },
  SetlastName: (data: any) => {
    return {
      type: actions.SET_LAST_NAME,
      data,
    };
  },
  SetBussinessName: (data: any) => {
    return {
      type: actions.SET_BUSSINESS_NAME,
      data,
    };
  },
  SetUserTocken: (data: any) => {
    return {
      type: actions.SET_USER_TOCKEN,
      data,
    };
  },
  SetPostSignup: (data: any) => {
    return {
      type: actions.SET_POST_SIGNUP,
      data,
    };
  },
  SetUserData: (data: any) => {
    return {
      type: actions.SET_USER_DATA,
      data,
    };
  },
  SetCompanyId: (data: any) => {
    return {
      type: actions.SET_COMPANY_ID,
      data,
    };
  },
  SetFinancialYear: (data: any) => {
    return {
      type: actions.SET_FINANCIAL_YEAR,
      data,
    };
  },
  setLoading: (data: any) => {
    return {
      type: actions.IS_LOADING,
      data,
    };
  },
  setDownload: (data: any) => {
    return {
      type: actions.IS_DOWNLOADING,
      data,
    };
  },
  reset: () => {
    return { type: actions.IS_RESET };
  },

  setCompanyAdded: (data: any) => {
    return {
      type: actions.IS_COMPANY_ADDED,
      data,
    };
  },

  setAddCompanyEmail: (data: any) => {
    return {
      type: actions.ADD_COMPANY_EMAIL,
      data,
    };
  },
};

export default actions;
