import { useEffect, useState } from "react";
import InputFIeld from "../components/InputFIeld";
import MyJson from "../Helper/india_state_city.json";
import "../styles/businessForm.css";
import CreatableSelect from "react-select/creatable";
// import Select from "react-select";
import Select from "@mui/material/Select";
import API from "../Helper/Api";
import { TextField, Tooltip } from "@material-ui/core";
// import { MdInfoOutline } from "react-icons/md";
import { connect } from "react-redux";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import swal from "sweetalert";

interface Props {
  companyId: string;
}

const BusinessInfoUpdate = (Props: Props) => {
  const [errorMessages, seterrorMessages] = useState<Array<any>>([]);
  const [emp, setemp] = useState([]);
  const [empIndex, setEmpIndex] = useState("");
  const [TypeBussiness, setTypeBussiness] = useState([]);
  const [TypeBussinessIndex, setTypeBussinessIndex] = useState("");
  const [Activity, setActivity] = useState([]);
  const [ActivityIndex, setActivityIndex] = useState("");
  const [salary, setsalary] = useState([]);
  const [salaryIndex, setSalaryIndex] = useState("");
  const [name, setname] = useState("");
  const [address, setaddress] = useState("");
  const [city, setcity] = useState("");
  const [state, setstate] = useState("");
  const [other_branch, setother_branch] = useState([]);
  const [gstNumber, setGstNumber] = useState("");
  const statedata = [...new Set(MyJson.map((item) => item.state))];
  const cityData = [
    ...new Set(
      MyJson.map(({ name, state }) => JSON.stringify({ name, state }))
    ),
  ].map((json) => JSON.parse(json));

  //sorting cityData array
  cityData.sort((a, b) => {
    let cityA = a.name.toLowerCase(),
      cityB = b.name.toLowerCase();

    if (cityA < cityB) {
      return -1;
    }
    if (cityA > cityB) {
      return 1;
    }
    return 0;
  });

  const cityName = [
    ...new Set(
      cityData.map((item) => {
        return item.name;
      })
    ),
  ];

  useEffect(() => {
    LoadData();
    GetBussinessData();
    return () => {};
  }, [Props.companyId]);

  const ShowError = (name: string) => {
    return errorMessages.filter((x) => x.name === name).length > 0;
  };
  const GetMessage = (name: string) => {
    return errorMessages.filter((x) => x.name === name)[0].message;
  };
  const GetBussinessData = () => {
    let tocken = localStorage.getItem("@tocken");
    const AuthStr = "Bearer ".concat(tocken || "");
    API.get(`user/bussiness`, {
      headers: { Authorization: AuthStr, companyId: Props.companyId },
    })
      .then((data) => {
        // console.log("Business data: ", data.data);
        let otheraddress: Array<any> = [];
        data.data.data.other_branch.map((item: any, index: number) => {
          otheraddress.push({ name: item, label: item });
        });
        setother_branch(data.data.data.other_branch);

        setname(data.data.data.name);
        setaddress(data.data.data.address);
        setname(data.data.data.name);
        setcity(data.data.data.city);
        setstate(data.data.data.state);
        setTypeBussinessIndex(data.data.data.type);
        setSalaryIndex(data.data.data.revenue);
        setEmpIndex(data.data.data.employees);
        setActivityIndex(data.data.data.activity);
        setGstNumber(data.data.data.gst);
      })
      .catch((error) => {});
  };
  const LoadData = () => {
    API.get(`bussinesstype/getall/active`)
      .then((data) => {
        setTypeBussiness(data.data.data.filter((element: any) => element.title !== "all"));
      })
      .catch((error) => {});
    API.get(`bussinessactivity/getall/active`)
      .then((data) => {
        setActivity(data.data.data.filter((element: any) => element.title !== "all"));
      })
      .catch((error) => {});
    API.get(`bussinessrevenue/getall/active`)
      .then((data) => {
        setsalary(data.data.data.filter((element: any) => element.title !== "all"));
      })
      .catch((error) => {});
    API.get(`bussinessemployees/getall/active`)
      .then((data) => {
        setemp(data.data.data.filter((element: any) => element.title !== "all"));
      })
      .catch((error) => {});
  };
  const SubmitFrom = () => {
    let tocken = localStorage.getItem("@tocken");
    const AuthStr = "Bearer ".concat(tocken || "");
    seterrorMessages([]);
    API.patch(
      `user/updatebussinessinfo`,
      {
        name: name,
        address: address,
        city: city,
        state: state,
        other_branch: other_branch.map((item: any, index: number) => {
          return item;
        }),
        typeofbussiness: TypeBussinessIndex,
        activity: ActivityIndex,
        employees: empIndex,
        revenue: salaryIndex,
      },
      {
        headers: { Authorization: AuthStr, companyId: Props.companyId },
      }
    )
      .then((data) => {
        if (data.data.status) {
          swal("Success", "Data Update Successfully!", "success");

          GetBussinessData();
        } else {
          if (data.data.valData) {
            let dataerrer = data.data.valData.errors.map(
              (item: any, index: number) => {
                return { name: item.param, message: item.msg };
              }
            );
            seterrorMessages(dataerrer);
          } else {
            let item = { name: "all", message: data.data.message };
            seterrorMessages([item]);
          }
        }
      })
      .catch((error) => {});
  };

  const handleChange = (event: any, newValue: any) => {
    setother_branch(newValue);
  };
  const handleChangeSingle = (event: any) => {
    setcity("");
    setstate(event.target.value);
  };
  const handleChangeSingleCity = (event: any) => {
    setcity(event.target.value);
  };

  const handleChangeBussiness = (event: any) => {
    // setGSTCurentData(event.target.value);
    setTypeBussinessIndex(event.target.value);
  };
  const handleBusinessActivity = (event: any) => {
    setActivityIndex(event.target.value);
  };
  const handleNumberEmp = (event: any) => {
    setEmpIndex(event.target.value);
  };
  const handleLastRevenue = (event: any) => {
    setSalaryIndex(event.target.value);
  };
  //Result will be an filteredArray which returns title.
  const findValByKey = (arr: any[], key: string) => {
    const value = arr.filter((itemInArray) => itemInArray?.id == key);
    return value?.length > 0 ? value[0]?.id : "";
  };

  return (
    <form className="update-form-container">
      <div className="form-hedding">Business Info</div>
      <div className="update-form">
        <div className="box1">
          <div className="form-control">
            <div className="input__box11">
              <TextField
                id="outlined-basic"
                value={name}
                onChange={(e: any) => {
                  setname(e.target.value);
                }}
                label="Name of Business"
                variant="outlined"
                className="input-field"
              />
            </div>
            {ShowError("name") ? (
              <span className="tooltiptext">{GetMessage("name")}</span>
            ) : null}
          </div>

          <div className="form-control">
            <InputFIeld
              placeholder="Address"
              showError={true}
              value={address}
              disable={false}
              onChange={(e: any) => {
                setaddress(e.target.value);
              }}
            />
            {ShowError("address") ? (
              <span className="tooltiptext">{GetMessage("address")}</span>
            ) : null}
          </div>

          <div className="form-control">
            <Box>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label-s">
                  Select State
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label-s"
                  id="demo-simple-select"
                  value={state?.length > 0 ? state : ""}
                  label="Select State"
                  onChange={handleChangeSingle}
                >
                  {statedata.sort().map((item: any, index) => {
                    return (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
            {ShowError("state") ? (
              <span className="tooltiptext">{GetMessage("state")}</span>
            ) : null}
          </div>

          <div className="form-control">
            <Box>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label-c">
                  Select City
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label-c"
                  id="demo-simple-select1"
                  value={city.length > 0 ? city : ""}
                  label=" Select City"
                  onChange={handleChangeSingleCity}
                >
                  {[...new Set(cityData.filter((x) => x.state == state))].map(
                    (item, index) => {
                      return city.includes(item.name) ? (
                        <MenuItem value={city}>{city}</MenuItem>
                      ) : (
                        <MenuItem value={item.name}>{item.name}</MenuItem>
                      );
                    }
                  )}
                </Select>
              </FormControl>
            </Box>
            {ShowError("city") ? (
              <span className="tooltiptext">
                {GetMessage("city")}
                <br />
              </span>
            ) : null}
          </div>

          <div className="form-control">
            {/* <Autocomplete
              className="multi-cehcked"
              multiple
              id="tags-outlined"
              options={cityData.map((item, index) => {
                return item.name;
              })}
              getOptionLabel={(option: any) => option}
              onChange={handleChange}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  value={other_branch}
                  defaultValue={other_branch}
                  label="Other Branches"
                  placeholder="Other Branch"
                  className="auto-complte"
                />
              )}
            /> */}
            {/* <Autocomplete
        multiple
        id="tags-outlined"
        options={top100Films}
        getOptionLabel={(option) => option.title}
        defaultValue={[top100Films[13]]}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            label="filterSelectedOptions"
            placeholder="Favorites"
          />
        )}
      /> */}
            {/* <Autocomplete
              // className="multi-cehcked"
              multiple
              id="tags-outlined"
              options={cityData.map((item, index) => {
                return item.name;
              })}
              
              getOptionLabel={(option: any) => option}
              onChange={handleChange}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  value={other_branch}
                  label="Other Branches"
                  placeholder="Other Branch"
                  //className="auto-complte"
                />

              )}
            /> */}
            <div className="form-control">
              <Autocomplete
                className="multi-cehcked"
                multiple
                id="tags-outlined"
                options={cityName}
                value={other_branch}
                getOptionLabel={(option: any) => option}
                onChange={handleChange}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Other Branches"
                    placeholder="Other Branch"
                    className="auto-complte"
                  />
                )}
              />
              {ShowError("other_branch") ? (
                <span className="tooltiptext">
                  {GetMessage("other_branch")}
                </span>
              ) : null}
            </div>
          </div>
        </div>

        <div className="box2">
          <div className="form-control">
            {/* {ShowError("activity") ? (
              <span className="tooltiptext">{GetMessage("activity")}</span>
            ) : null} */}
            <Box>
              <FormControl fullWidth>
                <TextField
                  id="outlined-basic"
                  //The nullish coalescing operator (??) is used to prevent overlapping in 'GST number' input form
                  value={gstNumber ?? ""}
                  // onChange={(e: any) => {
                  //   setname(e.target.value);
                  // }}
                  label="GST Number"
                  variant="outlined"
                  className="input-field"
                  disabled
                />

                {/* <InputLabel id="demo-simple-select-label-g">
                  GST Number
                </InputLabel>

                <Select
                  labelId="demo-simple-select-label-g"
                  id="demo-simple-select"
                  // value={bussinessActivity}
                  label="Main business activity"
                  //onChange={handleBusinessActivity}
                >
                  {/* {Activity.map((item: any, index) => {
                    return <MenuItem value={item.title}>{item.title}</MenuItem>;
                  })} */}
                {/* <MenuItem value={"29AAGCC3954F123Z"}>
                    29AAGCC3954F123Z
                  </MenuItem> */}
                {/* </Select>  */}
              </FormControl>
            </Box>
          </div>
          <div className="form-control">
            {ShowError("typeofbussiness") ? (
              <span className="tooltiptext">
                {GetMessage("typeofbussiness")}
              </span>
            ) : null}

            <Box>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label-b">
                  Type of Business
                </InputLabel>

                <Select
                  labelId="demo-simple-select-label-b"
                  id="demo-simple-select"
                  value={findValByKey(TypeBussiness, TypeBussinessIndex)}
                  defaultValue={findValByKey(TypeBussiness, TypeBussinessIndex)}
                  label="Type of Business"
                  onChange={handleChangeBussiness}
                >
                  {TypeBussiness.map((item: any, index) => {
                    return (
                      <MenuItem key={index} value={item?.id}>
                        {item?.title}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </div>
          <div className="form-control">
            {ShowError("activity") ? (
              <span className="tooltiptext">{GetMessage("activity")}</span>
            ) : null}
            <Box>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label-a">
                  Main business activity
                </InputLabel>

                <Select
                  labelId="demo-simple-select-label-a"
                  id="demo-simple-select"
                  value={findValByKey(Activity, ActivityIndex)}
                  // defaultValue={ActivityIndex}
                  label="Main business activity"
                  onChange={handleBusinessActivity}
                >
                  {Activity.map((item: any, index) => {
                    return (
                      <MenuItem key={index} value={item?.id}>
                        {item?.title}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </div>

          <div className="form-control">
            {ShowError("employees") ? (
              <span className="tooltiptext">{GetMessage("employees")}</span>
            ) : null}

            <Box>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label-e">
                  Number of Employees
                </InputLabel>

                <Select
                  labelId="demo-simple-select-label-e"
                  id="demo-simple-select"
                  value={findValByKey(emp, empIndex)}
                  // defaultValue={empIndex}
                  label="Number of Employees"
                  onChange={handleNumberEmp}
                >
                  {emp.map((item: any, index) => {
                    return (
                      <MenuItem key={index} value={item?.id}>
                        {item.title}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </div>
          <div className="form-control">
            {ShowError("revenue") ? (
              <span className="tooltiptext">{GetMessage("revenue")}</span>
            ) : null}

            <Box>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label-r">
                  Last year Revenue
                </InputLabel>

                <Select
                  labelId="demo-simple-select-label-r"
                  id="demo-simple-select"
                  value={findValByKey(salary, salaryIndex)}
                  //  defaultValue={findValByKey(salary,salaryIndex)}
                  label="Number of Employees"
                  onChange={handleLastRevenue}
                >
                  {salary.map((item: any, index) => {
                    return (
                      <MenuItem key={index} value={item?.id}>
                        {item.title}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </div>

          {/* {ShowError("typeofbussiness") ? (
            <span className="tooltiptext">{GetMessage("typeofbussiness")}</span>
          ) : null}
          <div className="form__multi-btns">
            {TypeBussiness.map((item: any, index: number) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    setTypeBussinessIndex(item.id);
                    setActivityIndex("");
                    setEmpIndex("");
                    setSalaryIndex("");
                  }}
                  className={
                    TypeBussinessIndex === item.id ? "selected-emp" : ""
                  }
                >
                  {item.title}
                </div>
              );
            })}
          </div> */}
          {/* <p
            className="small-text"
            style={{
              fontSize: "13px",
              color: "#7A7A7A",
              marginBottom: "3px",
            }}
          >
            <span>Main business activity</span>
            <span className="input-required small-required">
              *
              <Tooltip
                title="To provide the reminder for compliance which are specific to any business, for eg- manufacturing activities will cover shops and establishments compliances"
                placement="right-start"
                arrow
              >
                <p>
                  <MdInfoOutline
                    size={16}
                    color="#000"
                    style={{
                      marginLeft: "5px",
                      color: "#000",
                    }}
                  />
                </p>
              </Tooltip>
            </span>
          </p> */}
          {/* {ShowError("activity") ? (
            <span className="tooltiptext">{GetMessage("activity")}</span>
          ) : null}
          <div className="form__multi-btns">
            {Activity.map((item: any, index: number) => {
              return (
                <div
                  key={index}
                  onClick={() => setActivityIndex(item.id)}
                  className={ActivityIndex === item.id ? "selected-emp" : ""}
                >
                  {item.title}
                </div>
              );
            })}
          </div> */}
        </div>
        {/* <div className="box3">
          <p className="form-head-label">
            Number of Employees
            <span className="input-required small-required">
              *
              <Tooltip
                title="To provide the details in respect to labour laws, professional tax etc."
                placement="right-start"
                arrow
              >
                <p>
                  <MdInfoOutline
                    size={16}
                    color="#000"
                    style={{
                      marginLeft: "5px",
                      color: "#000",
                    }}
                  />
                </p>
              </Tooltip>
            </span>
          </p>
          {ShowError("employees") ? (
            <span className="tooltiptext">{GetMessage("employees")}</span>
          ) : null}
          <div className="form__multi-btns">
            {emp.map((item: any, index: number) => {
              return (
                <div
                  key={index}
                  onClick={() => setEmpIndex(item.id)}
                  className={empIndex === item.id ? "selected-emp" : ""}
                >
                  {item.title}
                </div>
              );
            })}
          </div>
          <p className="form-head-label">
            Last year Revenue
            <span className="input-required small-required">
              *
              <Tooltip
                title="To provide the details for GST, Tax Audit, Statutory audit etc."
                placement="right-start"
                arrow
              >
                <p>
                  <MdInfoOutline
                    size={16}
                    color="#000"
                    style={{
                      marginLeft: "5px",
                      color: "#000",
                    }}
                  />
                </p>
              </Tooltip>
            </span>
          </p>
          {ShowError("revenue") ? (
            <span className="tooltiptext">{GetMessage("revenue")}</span>
          ) : null}
          <div className="form__multi-btns">
            {salary.map((item: any, index: number) => {
              return (
                <div
                  key={index}
                  onClick={() => setSalaryIndex(item.id)}
                  className={salaryIndex === item.id ? "selected-emp" : ""}
                >
                  {item.title}
                </div>
              );
            })}
          </div>
        </div> */}
      </div>
      <Button
        variant="contained"
        onClick={() => {
          SubmitFrom();
        }}
        className="profile-btn"
      >
        update
      </Button>
      {/* <button
        className="update-form-btn"
        type="button"
        onClick={() => {
          SubmitFrom();
        }}
      >
        update
      </button> */}
    </form>
  );
};

const mapStateToProps = (state: any) => ({
  // first_name: state.user.first_name,
  // last_name: state.user.last_name,
  // bussness_name: state.user.bussness_name,
  // user_data: state.user.user_data,
  companyId: state.user.companyId,
});
const mapDispatchToProps = (dispatch: any) => ({
  // startAction: () => dispatch(startAction),
  // stopAction: () => dispatch(stopAction)
  // SetCompId: (data: string) => dispatch(SetCompId(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BusinessInfoUpdate);
