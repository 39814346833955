import { useEffect, useState } from "react";
import "../styles/authForm.css";
import google from "../images/google.png";
import { signInWithGoogle, auth } from "../components/Firebase.utils";
import API from "../Helper/Api";
import { connect } from "react-redux";
import { SetSignupName, deleteDetails } from "../reducers/user/actionCreator";
import { Button, Checkbox, TextField } from "@mui/material";
// import { ReactComponent as Close_icon } from "../images/close.svg";
import PersonalInfoForm from "../pages/PersonalInfoForm";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useDispatch } from "react-redux";

interface Props {
  showPass: boolean;
  setShowPass: any;
  showLogin: boolean;
  setShowLogin: any;
  onpress: any;
  SetSignupName: any;
}
const SignupForm = (Props: Props) => {
  let unsubscribeFromAuth: any;
  const [errorMessages, seterrorMessages] = useState<Array<any>>([]);
  const [email, setemail] = useState("");
  const [socialid, setsocialid] = useState("");
  const [password, setpassword] = useState("");
  const [isTearm, setisTearm] = useState(false);

  //dispatch
  const manualDispatch = useDispatch();

  const ShowError = (name: string) => {
    return errorMessages.filter((x) => x.name === name).length > 0;
  };
  const GetMessage = (name: string) => {
    return errorMessages.filter((x) => x.name === name)[0].message;
  };
  const SubmitFrom = () => {
    localStorage.clear();
    localStorage.removeItem("@tocken");
    localStorage.removeItem("company_id");
    manualDispatch(deleteDetails());
    seterrorMessages([]);
    if (socialid.length > 0) {
      API.post(`user/signup/social`, {
        socialid: socialid,
        email: email?.toLowerCase(),
      }).then((datasocial) => {
        if (datasocial.data.status) {
          localStorage.setItem("@tocken", datasocial.data.token);
          Props.onpress(2);
        } else {
        }
      });
    } else {
      API.post(`user/signup`, {
        password: password,
        email: email?.toLowerCase(),
        istearm: isTearm ? 1 : 0,
      })
        .then((data) => {
          if (data.data.status) {
            localStorage.setItem("@tocken", data.data.token);
            localStorage.setItem("isInvited", data.data?.isInvited);
            localStorage.setItem(
              "isProfileCompleted",
              data.data?.isProfileCompleted
            );
            localStorage.setItem(
              "isAssociatedToCompany",
              data.data?.isAssociatedToCompany
            );
            Props.onpress(2);
          } else {
            if (data.data.valData) {
              let dataerrer = data.data.valData.errors.map(
                (item: any, index: number) => {
                  return { name: item.param, message: item.msg };
                }
              );
              seterrorMessages(dataerrer);
            } else {
              let item = { name: "all", message: data.data.message };
              seterrorMessages([item]);
            }
          }
        })
        .catch((error) => {
          const errorData = error.response.data;
          if (errorData) {
            if (errorData.valData) {
              let dataerrer = errorData.valData.errors.map(
                (item: any, index: number) => {
                  return { name: item.param, message: item.msg };
                }
              );
              seterrorMessages(dataerrer);
            } else {
              let item = { name: "all", message: errorData.message };
              seterrorMessages([item]);
            }
          }
        });
    }
  };

  const responseGoogle = (response: any) => {
    let email: any = null;
    let name: any = null;
    if (response[0].displayName) {
      name = response[0].displayName;
      if (response[0].email) {
        email = response[0].email;
      } else {
        email = null;
      }
      if (response[0].displayName) {
        Props.SetSignupName(response[0].displayName);
      }
      API.post(`user/social/check`, {
        socialid: response[0].uid,
        email: email,
      })
        .then((data) => {
          if (data.data.status) {
            localStorage.clear();
            localStorage.removeItem("@tocken");
            localStorage.removeItem("company_id");
            manualDispatch(deleteDetails());
            localStorage.setItem("@tocken", data.data.token);

            localStorage.setItem("isInvited", data.data?.isInvited);
            localStorage.setItem(
              "isProfileCompleted",
              data.data?.isProfileCompleted
            );
            localStorage.setItem(
              "isAssociatedToCompany",
              data.data?.isAssociatedToCompany
            );

            if (data.data.isInvited) {
              Props.onpress(2); //contact info
            } else if (!data.data.isProfileCompleted) {
              Props.onpress(2);
            } else {
              if (data.data.verified) {
                Props.onpress(1);
              } else {
                //Props.onpress(0) ==> 0 to check Login verificatio & stay at /login page...To bypass Login verification and redirect to /dashboard page for staging/production pass argument = '1' as Props.onpress(1);
                let item = {
                  name: "notverify",
                  message:
                    "Pls verify your account through the link sent on your registered email id",
                };
                seterrorMessages([item]);
                Props.onpress(0);
              }
            }
          } else {
            if (email) {
              API.post(`user/signup/social`, {
                socialid: response[0].uid,
                email: email?.toLowerCase(),
                name: response[0].displayName,
              }).then((data) => {
                if (data.data.status) {
                  localStorage.setItem("@tocken", data.data.token);
                  localStorage.setItem("isInvited", data.data?.isInvited);

                  Props.onpress(2); // '/info' path
                }
              });
            } else if (data.data.valData) {
              let dataerrer = data.data.valData.errors.map(
                (item: any, index: number) => {
                  return { name: item.param, message: item.msg };
                }
              );
              seterrorMessages(dataerrer);
            } else {
              let item = { name: "all", message: data.data.message };
              seterrorMessages([item]);
            }
          }
        })
        .catch((error) => {
          const errorData = error.response.data;
          if (errorData) {
            if (errorData.valData) {
              let dataerrer = errorData.valData.errors.map(
                (item: any, index: number) => {
                  return { name: item.param, message: item.msg };
                }
              );
              seterrorMessages(dataerrer);
            } else {
              let item = { name: "all", message: errorData.message };
              seterrorMessages([item]);
            }
          }
        });
    }
  };
  useEffect(() => {
    unsubscribeFromAuth = auth.onAuthStateChanged((user) => {
      if (user) {
        responseGoogle(user.providerData);
      }
    });

    return () => {
      unsubscribeFromAuth();
      auth.signOut();
    };
  }, []);
  return (
    <div className="right-section-wrp">
      <div className="right__form">
        <div className="right__form-wrapp">
          <form
            style={{
              width: "100%",
            }}
            onSubmit={(event) => {
              event.preventDefault();
              SubmitFrom();
            }}
          >
            {/* <p>Create Account</p> */}
            <div className="login-text">
              <div className="login-main-text">Register</div>
              <div className="login-sub-text">Get started for free</div>
            </div>
            <div className="form-control">
              {ShowError("all") ? (
                <span className="tooltiptext">{GetMessage("all")}</span>
              ) : null}
              {/* <label className="label-text">Email</label> */}
              <div className="form__input-box">
                <TextField
                  id="outlined-basic"
                  type="email"
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                  label="Email"
                  variant="outlined"
                  className="input-field"
                />
              </div>
              {ShowError("email") ? (
                <span className="tooltiptext">{GetMessage("email")}</span>
              ) : null}
            </div>
            <div className="form-control">
              {/* <label className="label-text">Password</label> */}
              <div className="">
                <TextField
                  type={Props.showPass ? "text" : "password"}
                  id="outlined-basic"
                  label="Password"
                  variant="outlined"
                  // required
                  value={password}
                  className="input-field"
                  onChange={(e) => setpassword(e.target.value)}
                />
              </div>
              {ShowError("password") ? (
                <span className="tooltiptext">{GetMessage("password")}</span>
              ) : null}
            </div>
            <label className="signup_terms">
              <Checkbox
                className="check-box-m"
                checked={isTearm}
                onChange={() => setisTearm(!isTearm)}
              />
              <p className="signup_terms-text">
                I accept the
                <a
                  href="#"
                  onClick={() => {
                    window.location.href = "/terms";
                  }}
                >
                  Terms{" "}
                </a>
                and
                <a
                  href="#"
                  onClick={() => {
                    window.location.href = "/privacy";
                  }}
                >
                  Conditions
                </a>{" "}
              </p>
            </label>
            {ShowError("istearm") ? (
              <span className="tooltiptext">{GetMessage("istearm")}</span>
            ) : null}

            <Button
              variant="contained"
              type="submit"
              className="business_form-submit-btn"
              endIcon={<ArrowForwardIcon />}
            >
              Register
            </Button>
          </form>

          <div className="or-border">
            <p>Or</p>
          </div>

          <div
            className="google_signin-btn"
            onClick={() => {
              signInWithGoogle().catch((error: any) => {});
            }}
          >
            <img
              src={google}
              alt="google logo"
              style={{
                width: "16px",
                height: "16px",
              }}
            />
            <span>Continue with Google</span>
          </div>

          <div className="not-registrer">
            {/* <span className="not-text">Have account?</span> */}
            <span
              className="signup-text"
              onClick={() => {
                Props.setShowLogin(!Props.showLogin);
              }}
            >
              Login
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  first_name: state.user.first_name,
  last_name: state.user.last_name,
  bussness_name: state.user.bussness_name,
});
const mapDispatchToProps = (dispatch: any) => ({
  SetSignupName: (name: string) => dispatch(SetSignupName(name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignupForm);
