import { useState } from "react";
import "../styles/authForm.css";
import API from "../Helper/Api";
import { connect } from "react-redux";
import { TextField, Button } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import swal from "sweetalert";

interface Props {
  showPass: boolean;
  setShowPass: any;
  showLogin: boolean;
  setShowLogin: any;
  onpress: any;
  onresetpress: any;
}
const PasswordResetForm = (Props: Props) => {
  const [errorMessages, seterrorMessages] = useState<Array<any>>([]);
  const [email, setemail] = useState("");

  const ShowError = (name: string) => {
    return errorMessages.filter((x) => x.name === name).length > 0;
  };
  const GetMessage = (name: string) => {
    return errorMessages.filter((x) => x.name === name)[0].message;
  };
  const SubmitFrom = () => {
    seterrorMessages([]);
    API.post(`user/forgot/password`, {
      email: email?.toLowerCase(),
    })
      .then((data) => {
        // console.log('Forgot password data: ',data)

        if (data.data.status) {
          // debugger
          // window.localStorage.setItem('fg_password',data.data.status)
          // Props.onpress()
          swal({
            title: "Password Reset Email Sent!",
            text: `A link to reset your password has been sent to your email address: ${email}.`,
            icon: "success",
          }).then(
            () => Props.onpress(0) //Page redirected to /login page after password reset
          );

          //simple tooltip text for password reset email sent
          // let item = { name: "all", message: data.data.message };
          //   seterrorMessages([item]);
        } else {
          if (data.data.valData) {
            let dataerrer = data.data.valData.errors.map(
              (item: any, index: number) => {
                return { name: item.param, message: item.msg };
              }
            );
            seterrorMessages(dataerrer);
            // console.log(dataerrer);
          } else {
            let item = { name: "all", message: data.data.message };
            seterrorMessages([item]);
          }
        }
      })
      .catch((error) => {
        const errorData = error.response.data;
        if (errorData) {
          if (errorData.valData) {
            let dataerrer = errorData.valData.errors.map(
              (item: any, index: number) => {
                return { name: item.param, message: item.msg };
              }
            );
            seterrorMessages(dataerrer);
          } else {
            let item = { name: "all", message: errorData.message };
            seterrorMessages([item]);
          }
        }
      });
  };

  return (
    <div className="right__form forgot-page">
      <div className="right__form-wrapp">
        <form
          onSubmit={(event) => {
            event.preventDefault();
            SubmitFrom();
          }}
        >
          <div className="login-text">
            <div className="login-main-text">Forgot Password</div>
            <div className="login-sub-text">
              Enter your email address we'll send a reset link
            </div>
          </div>

          {ShowError("all") ? (
            <span className="tooltiptext">{GetMessage("all")}</span>
          ) : null}
          <div className="form__input-box">
            <TextField
              id="outlined-basic"
              type="email"
              value={email}
              onChange={(e) => setemail(e.target.value?.toLowerCase())}
              label="Email"
              variant="outlined"
              className="input-field"
            />
          </div>
          {ShowError("email") ? (
            <span className="tooltiptext">{GetMessage("email")}</span>
          ) : null}

          <Button
            variant="contained"
            type="submit"
            className="business_form-submit-btn"
            endIcon={<ArrowForwardIcon />}
          >
            Reset Password
          </Button>
        </form>
        <div className="not-registrer">
          <span
            className="signup-text"
            onClick={() => {
              Props.onresetpress();
            }}
          >
            Back Login
          </span>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  first_name: state.user.first_name,
  last_name: state.user.last_name,
  bussness_name: state.user.bussness_name,
});
const mapDispatchToProps = (dispatch: any) => ({
  // stopAction: () => dispatch(stopAction)
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordResetForm);
