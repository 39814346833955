import React, { useEffect, useState } from "react";
import "../styles/authForm.css";

import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import API from "../Helper/Api";
import { ReactComponent as Close_icon } from "../images/close.svg";
import InputFIeld from "../components/InputFIeld";
import { Button } from "@mui/material";
import vector from '../images/Vector.png'
import semicircle from '../images/semi-circle.png';
import greencircle from '../images/green-circle.png';
import linecircle from '../images/line-shape.png'
import spark from '../images/shape.png'
import Logo from "../images/MF.png";
import swal from "sweetalert";

import { connect } from "react-redux";
interface Props {
  onpress: any;

  onresetpress: any;
  code: string;
}
const PasswordUpdateForm = (Props: Props) => {
  const [errorMessages, seterrorMessages] = useState<Array<any>>([]);
  const [cnf_password, setcnf_password] = useState("");
  const [password, setpassword] = useState("");
  const [showPass, setshowPass] = useState(false);
  const [cnfshowPass, setcnfshowPass] = useState(false);
  const ShowError = (name: string) => {
    return errorMessages.filter((x) => x.name === name).length > 0;
  };
  const GetMessage = (name: string) => {
    return errorMessages.filter((x) => x.name === name)[0].message;
  };
  const SubmitFrom = () => {
    seterrorMessages([]);
    API.patch(`user/forgot/password/update`, {
      new_password: password,
      confirm_password: cnf_password,
      code: Props.code,
    })
      .then((data) => {
        if (data.data.status) {
          localStorage.setItem("@tocken", data.data.token);

          swal({
            title: "Password Reset Successfully!",
            text: `The password has been reset successfully.`,
            icon: "success",
          }).then(
            () => Props.onpress() //redirected to /login page after password reset
          );
          
        } else {
          if (data.data.valData) {
            let dataerrer = data.data.valData.errors.map(
              (item: any, index: number) => {
                return { name: item.param, message: item.msg };
              }
            );
            seterrorMessages(dataerrer);

            // console.log(dataerrer);
          } else {
            let item = { name: "all", message: data.data.message };
            seterrorMessages([item]);
          }
        }
      })
      .catch((error) => {});
  };

  return (
    <>
      {/* ||Header */}
      <div className="login-auth">
        <div className="auth-Header">
          <div className="logo-icon">
            <img src={Logo} alt="mf logo" />
          </div>
        </div>

        <div className="right user-page forgot-password">
          <img src={semicircle} alt="" className="semi-circle" />
          <img src={greencircle} alt="" className="green-cricle" />
          <img src={linecircle} alt="" className="line-shape" />
          <img src={spark} alt="" className="forgetPassSparks center" />

          <div className=" right__form forgot-page">
            <div className="right__form-wrapp">
              <form>
                <div className="login-text">
                  <div className="login-main-text">Setup New Password</div>
                  {/* <div className="login-sub-text">Subtext
              </div> */}
                </div>

                <div className="form-control">
                  <div className="main__inputbox1">
                    <InputFIeld
                      type={"password"}
                      placeholder="Enter new password"
                      disable={false}
                      showError={true}
                      value={password}
                      onChange={(e: any) => {
                        setpassword(e.target.value);
                      }}
                    />
                  </div>
                  {ShowError("password") ? (
                    <span className="tooltiptext">
                      {GetMessage("password")}
                    </span>
                  ) : null}
                  {ShowError("new_password") ? (
                    <span className="tooltiptext">
                      {GetMessage("new_password")}
                    </span>
                  ) : null}
                </div>

                <div className="form-control inputHeight">
                  <div className="main__inputbox1">
                    <InputFIeld
                      type={"password"}
                      placeholder="Re-enter new password"
                      showError={true}
                      disable={false}
                      value={cnf_password}
                      onChange={(e: any) => {
                        setcnf_password(e.target.value);
                      }}
                    />
                  </div>
                  {ShowError("cnf_password") ? (
                    <span className="tooltiptext">
                      {GetMessage("cnf_password")}
                    </span>
                  ) : null}
                </div>

                <Button
                  variant="contained"
                  onClick={() => {
                    SubmitFrom();
                  }}
                  className="profile-btn updatePassword"
                >
                  Update password <img src={vector}></img>
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  first_name: state.user.first_name,
  last_name: state.user.last_name,
  bussness_name: state.user.bussness_name,
});
const mapDispatchToProps = (dispatch: any) => ({
  // stopAction: () => dispatch(stopAction)
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordUpdateForm);
