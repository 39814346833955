import React, { useEffect, useState } from "react";
import InputFIeld from "../components/InputFIeld";
import Navbar from "../components/Navbar";
import "../styles/profile.css";
import API from "../Helper/Api";
import { Button } from "@mui/material";
import swal from "sweetalert";
import { connect } from "react-redux";

// import validator from "@types/validator";
interface Props {
  
  companyId:string
}
const PersonalUpdate = (Props: Props) => {
  const [errorMessages, seterrorMessages] = useState<Array<any>>([]);
  const [first_name, setfirst_name] = useState("");
  const [last_name, setlast_name] = useState("");
  const [phone, setphone] = useState("");
  const [email, setemail] = useState("");
  const [phonecode, setphonecode] = useState("+91");
  useEffect(() => {
    GetBussinessData();
    return () => {};
  }, []);
  const ShowError = (name: string) => {
    return errorMessages.filter((x) => x.name === name).length > 0;
  };
  const GetMessage = (name: string) => {
    return errorMessages.filter((x) => x.name === name)[0].message;
  };
  const GetBussinessData = () => {
    let tocken = localStorage.getItem("@tocken");
    const AuthStr = "Bearer ".concat(tocken || "");
    // let id=localStorage.getItem(('company_id'))
     let id=Props.companyId
    API.get(`user/me`, {
      headers: { Authorization: AuthStr,companyId:id },
    })
      .then((data) => {
        setfirst_name(data.data.data.first_name);
        setlast_name(data.data.data.last_name);
        setphone(data.data.data.phone);
        setemail(data.data.data.email);
        if (data.data.data.phonecode) {
          setphonecode(data.data.data.phonecode);
        }
      })
      .catch((error) => {});
  };
  const SubmitFrom = () => {
    let tocken = localStorage.getItem("@tocken");
    const AuthStr = "Bearer ".concat(tocken || "");
    seterrorMessages([]);
    API.patch(
      `user/updateprofile`,
      {
        first_name: first_name,
        last_name: last_name,
        phone: phone,
        phonecode: phonecode,
      },
      {
        headers: { Authorization: AuthStr },
      }
    )
      .then((data) => {
        if (data.data.status) {
          GetBussinessData();
          swal("Success", data.data.message, "success")
        } else {
          if (data.data.valData) {
            let dataerrer = data.data.valData.errors.map(
              (item: any, index: number) => {
                return { name: item.param, message: item.msg };
              }
            );
            seterrorMessages(dataerrer);

            // console.log(dataerrer);
          } else {
            let item = { name: "all", message: data.data.message };
            seterrorMessages([item]);
          }
        }
      })
      .catch((error) => {});
  };

  const handlePhoneNumber = (phoneNumber: any) => {
    let isNum = /^\d+$/.test(phoneNumber);
    // let isValidPhoneNumber = validator.isMobilePhone(phoneNumber);
    // console.log('ivpn',isNum)
    if (isNum && phoneNumber.length <= 10 || phoneNumber === "" ) {
      setphone(phoneNumber)
    }
  };

  return (
    <form className="profile-form">
      <div className="profile-form-wrap">
        <div className="form-hedding">Contact Information</div>
        <div className="form-control">
          <InputFIeld
            placeholder="First Name"
            showError={false}
            disable={false}
            value={first_name}
            onChange={(e: any) => {
              setfirst_name(e.target.value);
            }}
          />
          {ShowError("first_name") ? (
            <span className="tooltiptext">{GetMessage("first_name")}</span>
          ) : null}
        </div>
        <div className="form-control">
          <InputFIeld
            placeholder="Last Name"
            showError={false}
            disable={false}
            value={last_name}
            onChange={(e: any) => {
              setlast_name(e.target.value);
            }}
          />
          {ShowError("last_name") ? (
            <span className="tooltiptext">{GetMessage("last_name")}</span>
          ) : null}
        </div>
        <div className="form-control">
          <div className="verified-input">
            <InputFIeld
              placeholder="Email"
              showSuccess={false}
              showError={false}
              disable={true}
              value={email}
              onChange={(e: any) => {}}
            />
          </div>
        </div>
        <div className="form-control">
          <div className="phone-input-box">
            <InputFIeld
              placeholder="Phone Number"
              showSuccess={false}
              showError={false}
              disable={false}
              value={phone}
              onChange={(e: any) => {
                handlePhoneNumber(e.target.value);
              }}
            />
          </div>

          {ShowError("phone") ? (
            <span className="tooltiptext">{GetMessage("phone")}</span>
          ) : null}
          {ShowError("phonecode") ? (
            <span className="tooltiptext">{GetMessage("phonecode")}</span>
          ) : null}
        </div>
        <Button
          variant="contained"
          onClick={() => {
            SubmitFrom();
          }}
          className="profile-btn"
        >
          update
        </Button>
      </div>
    </form>
  );
};

const mapStateToProps = (state: any) => (
  // console.log("mapstate", state),
  {
    signup_name: state.user.signup_name,
    post_sign_up: state.user.post_sign_up,
    companyId:state.user.companyId
  }
);
export default connect(mapStateToProps)(PersonalUpdate);

