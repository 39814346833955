import React, { useState } from "react";
import { Grid, Button, TextField } from "@mui/material";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import API from "../Helper/Api";
import { connect } from "react-redux";
import swal from "sweetalert";
import { FetchLogin, SetCompId } from "../reducers/user/actionCreator";
import { useDispatch } from "react-redux";

interface Props {
  companyId: string;
  user_data: any;
  FetchLogin?: any;
}

const Team = (Props: Props) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [inviteOpen, setInviteOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [deletingEmail, setDeletingEmail] = useState("");

  const handleDeleteMember = async () => {
    let tocken = localStorage.getItem("@tocken");
    const AuthStr = "Bearer ".concat(tocken || "");
    let removeRes = await API.post(
      "user/remove/member",
      {
        email: deletingEmail?.toLowerCase(),
        companyId: Props.companyId,
      },
      {
        headers: { Authorization: AuthStr },
      }
    );

    if (removeRes.data.status) {
      setOpen(false);
      swal("Deleted!", removeRes.data.message, "success");
      let id = localStorage.getItem("company_id");

      dispatch(SetCompId(""));
      localStorage.removeItem("company_id");
      Props.FetchLogin(id);
    }
  };

  const getAction = (params: any) => {
    return (
      <Button
        onClick={() => handleOpen(params.row.email)}
        style={{ backgroundColor: "#D81B60" }}
        variant="contained"
      >
        Delete
      </Button>
    );
  };

  const columns: GridColDef[] = [
    { field: "name", headerName: "Name", width: 320 },
    {
      field: "email",
      headerName: "Email",
      width: 320,
      cellClassName: "team-email-link",
    },
    {
      field: "action",
      headerName: "Action",
      width: 320,
      renderCell: getAction,
    },
  ];

  // const rows = [
  //   { id: 1, name: "Snow", email: "jon@gmail.com" },
  //   // { id: 2, name: "Lannister", email: "Cers
  // ];

  let rows = new Array();
  Props.user_data.bussinessdata?.members?.map((member: any, i: number) => {
    rows.push({
      id: i,
      name:
        member.first_name && member.last_name
          ? `${member.first_name} ${member.last_name}`
          : member.first_name
          ? `${member.first_name}`
          : member.last_name
          ? `${member.first_name}`
          : "",

      email: member.email,
    });
  });

  const handleOpen = (email: any) => {
    setOpen(true);
    setDeletingEmail(email);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInviteOpen = () => {
    setInviteOpen(true);
  };
  const handleInviteClose = () => {
    setEmail("");
    setInviteOpen(false);
  };

  const inviteMember = async () => {
    let tocken = localStorage.getItem("@tocken");
    const AuthStr = "Bearer ".concat(tocken || "");
    const inviteRes = await API.post(
      "user/invite",
      {
        email: email?.toLowerCase(),
        companyId: Props.companyId,
      },
      {
        headers: { Authorization: AuthStr },
      }
    );
    if (inviteRes.data.status) {
      setInviteOpen(false);
      setEmail("");
      swal("Success!", inviteRes.data.message, "success");
      Props.FetchLogin(Props.companyId);
    } else {
      swal("Error!", inviteRes.data.message, "error");
    }
  };

  return (
    <form className="update-form-container">
      <div style={{ display: "flex" }}>
        <div className="form-hedding">Team</div>
        <Button
          style={{ marginLeft: "auto" }}
          variant="contained"
          size="small"
          onClick={handleInviteOpen}
          className="invite-profile-btn"
        >
          INVITE MEMBER
        </Button>
      </div>
      {/* <Grid className="titles" container spacing={2}>
        <Grid item xs={4}>
          <strong>Name</strong>
        </Grid>
        <Grid item xs={4}>
          <strong>Email</strong>
        </Grid>
        <Grid item xs={4}>
          <strong>Actions</strong>
        </Grid>
        
      </Grid> */}
      <DataGrid
        style={{ border: "none", minHeight: 400 }}
        // rows={Props.user_data.bussinessdata.members}
        rows={rows}
        columns={columns}
        hideFooter
        // pageSize={5}
        // rowsPerPageOptions={[5]}
        // checkboxSelection
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Are you sure you want to delete the user ?</DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                style={{
                  backgroundColor: "#FFFFFF",
                  color: "black",
                  width: "100%",
                }}
                variant="contained"
                onClick={handleClose}
              >
                DON'T DELETE
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                style={{ backgroundColor: "#EF5350", width: "100%" }}
                variant="contained"
                onClick={handleDeleteMember}
              >
                DELETE
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <Dialog open={inviteOpen} onClose={handleInviteClose}>
        <DialogTitle>Invite Member</DialogTitle>
        <DialogContent style={{ paddingTop: "5px" }}>
          <TextField
            id="email"
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value?.toLowerCase())}
            variant="outlined"
            style={{ width: "372px" }}
          />
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                style={{
                  backgroundColor: "#FFFFFF",
                  color: "black",
                  width: "100%",
                }}
                onClick={handleInviteClose}
                variant="contained"
              >
                CANCEL
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                style={{ backgroundColor: "#673AB7", width: "100%" }}
                variant="contained"
                onClick={inviteMember}
              >
                INVITE
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </form>
  );
};

const mapStateToProps = (state: any) => ({
  companyId: state.user.companyId,
  user_data: state.user.user_data,
});
const mapDispatchToProps = (dispatch: any) => ({
  FetchLogin: () => dispatch(FetchLogin()),
  setCompanyId: (data: string) => dispatch(SetCompId(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Team);
