import React, { useState } from "react";
import "../styles/home.css";
import InputFIeld from "../components/InputFIeld";
import "../styles/newPassword.css";

import Navbar from "../components/Navbar";
import { connect } from "react-redux";
import API from "../Helper/Api";
import swal from "sweetalert";
import { Button } from "@mui/material";
import TextareaAutosize from "@mui/material/TextareaAutosize";

interface Props {
  location: any;
  history: any;
  first_name: string;
  last_name: string;
  bussness_name: string;
  companyId: string;
}
const SupportPage = (Props: Props) => {
  const [errorMessages, seterrorMessages] = useState<Array<any>>([]);
  const [title, settitle] = useState("");

  const [description, setdescription] = useState("");
  const ShowError = (name: string) => {
    return errorMessages.filter((x) => x.name === name).length > 0;
  };
  const GetMessage = (name: string) => {
    return errorMessages.filter((x) => x.name === name)[0].message;
  };
  const SubmitFrom = () => {
    let tocken = localStorage.getItem("@tocken");
    const AuthStr = "Bearer ".concat(tocken || "");
    seterrorMessages([]);
    API.post(
      `setting/add/support`,
      {
        title: title,
        description: description,
        companyId: Props.companyId,
      },
      {
        headers: { Authorization: AuthStr },
      }
    )
      .then((data) => {
        if (data.data.status) {
          swal("Success", data.data.message, "success");
          settitle("");
          setdescription("");
        } else {
          if (data.data.valData) {
            let dataerrer = data.data.valData.errors.map(
              (item: any, index: number) => {
                return { name: item.param, message: item.msg };
              }
            );
            seterrorMessages(dataerrer);

            // console.log(dataerrer);
          } else {
            let item = { name: "all", message: data.data.message };
            seterrorMessages([item]);
          }
        }
      })
      .catch((error) => {});
  };
  return (
    <div
      className="home-container"
      style={{
        height: "100%",
      }}
    >
      <Navbar history={Props.history} />

      <div className="main">
        <div className="main__head">
          <p className="main__head-profile">Support</p>
          <p className="main__head-name">
            Hello {Props.first_name.toLowerCase()}{" "}
            {Props.last_name.toLowerCase()} !
          </p>
          <p className="main__head-mark">{Props.bussness_name}</p>
        </div>

        <div className="main__tab top-padding">
          <button>Support</button>
        </div>

        <form className="main__changepass-form">
          <div className="form-control">
            <div className="main__inputbox11">
              <InputFIeld
                placeholder="Title"
                showError={true}
                disable={false}
                value={title}
                onChange={(e: any) => {
                  settitle(e.target.value);
                }}
              />
            </div>
            {ShowError("title") ? (
              <span className="tooltiptext">{GetMessage("title")}</span>
            ) : null}
          </div>
          <div className="form-control">
            <div className="main__inputbox11">
              <InputFIeld
                disable={false}
                fieldType={"textarea"}
                placeholder="Description"
                showError={true}
                value={description}
                onChange={(e: any) => {
                  setdescription(e.target.value);
                }}
              />
              {/* <TextareaAutosize
                maxRows={4}
                aria-label="maximum height"
                placeholder="Description"
                defaultValue="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                    ut labore et dolore magna aliqua."
                style={{ width: 200 }}
              /> */}
            </div>
            {ShowError("description") ? (
              <span className="tooltiptext">{GetMessage("description")}</span>
            ) : null}
          </div>
          <Button
            variant="contained"
            onClick={() => {
              SubmitFrom();
            }}
            className="profile-btn"
          >
            Send
          </Button>
          {/* <button
            type="button"
            onClick={() => {
              SubmitFrom();
            }}
          >
          
          </button> */}
        </form>
      </div>
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  first_name: state.user.first_name,
  last_name: state.user.last_name,
  bussness_name: state.user.bussness_name,
  companyId: state.user.companyId,
});
const mapDispatchToProps = (dispatch: any) => ({
  // startAction: () => dispatch(startAction),
  // stopAction: () => dispatch(stopAction)
});

export default connect(mapStateToProps, mapDispatchToProps)(SupportPage);
