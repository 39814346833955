import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "../styles/authForm.css";
import SidebarInfo from "../components/SidebarInfo";
import SigninForm from "../components/SigninForm";
import SignupForm from "../components/SignupForm";
import Logo from "../images/MF.png";
import semicircle from "../images/semi-circle.png";
import greencircle from "../images/green-circle.png";
import linecircle from "../images/line-shape.png";
import spark from "../images/shape.png";
import { deleteDetails } from "../reducers/user/actionCreator";
import { useDispatch } from "react-redux";
interface Props {
  location: any;
  history: any;
}
const AuthForm = (Props: Props) => {
  const location = useLocation();
  const manualDispatch = useDispatch();
  useEffect(() => {
    if (location.pathname == "/login") {
      setShowLogin(true);
    } else {
      setShowLogin(false);
    }
    localStorage.clear();
    manualDispatch(deleteDetails());
    return () => {};
  }, [location.pathname]);
  const [showPass, setShowPass] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

  return (
    <div className="login-auth">
      <div className="auth-Header">
        <div className="logo-icon">
          <img src={Logo} alt="mf logo" />
        </div>
      </div>
      <div className="right login-panel">
        <img src={semicircle} alt="" className="semi-circle" />
        <img src={greencircle} alt="" className="green-cricle" />
        <img src={linecircle} alt="" className="line-shape" />
        <img src={spark} alt="" className="sparks" />

        <SidebarInfo />
        {showLogin ? (
          <SigninForm
            showPass={showPass}
            setShowLogin={(val: boolean) => {
              if (val) {
                Props.history.push("/login");
              } else {
                Props.history.push("/signup");
              }
            }}
            showLogin={showLogin}
            setShowPass={setShowPass}
            onresetpress={() => {
              Props.history.push("/reset");
            }}
            onpress={(val: number) => {
              // console.log('onpress value: ',val)
              if (val == 1) {
                Props.history.push("/dashboard");
              } else if (val == 2) {
                //Props.history.push("/acountsetup");
                Props.history.push("/info");
              } else {
                Props.history.push("/login");
              }
            }}
          />
        ) : (
          <SignupForm
            showPass={showPass}
            setShowLogin={(val: boolean) => {
              if (val) {
                Props.history.push("/login");
              } else {
                Props.history.push("/signup");
              }
            }}
            showLogin={showLogin}
            setShowPass={setShowPass}
            onpress={(val: number) => {
              if (val == 1) {
                Props.history.push("/dashboard");
              } else if (val == 2) {
                Props.history.push("/info");
              } else {
                Props.history.push("/login");
              }
            }}
          />
        )}
      </div>
    </div>
  );
};

export default AuthForm;
