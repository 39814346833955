import React, { useEffect, useState } from "react";
import "../styles/authForm.css";
import SidebarInfo from "../components/SidebarInfo";
import PasswordUpdateForm from "../components/PasswordUpdateForm";

interface Props {
  history: any;
  match: any;
}
const ForgotUpdatePassword = (Props: Props) => {
  const [code, setcode] = useState("");

  useEffect(() => {
    if (Props.match.params.param && Props.match.params.param.length > 0) {
      setcode(Props.match.params.param);
    }
    return () => {};
  }, []);
  return (
    <div className="login-auth">
      {/* <SidebarInfo /> */}
      <div className="right">
        <PasswordUpdateForm
          code={code}
          onresetpress={() => {
            Props.history.push("/login");
          }}
          //After setting new password page redirected to /login instead of /dashboard
          onpress={() => {
            Props.history.push("/login");
          }}
        />
      </div>
    </div>
  );
};

export default ForgotUpdatePassword;
