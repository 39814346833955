import actions from "./actions";

const {
  SET_IS_LOGIN,
  SET_FISRT_NAME,
  SET_USER_TOCKEN,
  SET_LAST_NAME,
  SET_BUSSINESS_NAME,
  SET_SIGNUP_NAME,
  SET_POST_SIGNUP,
  SET_USER_DATA,
  SET_COMPANY_ID,
  SET_FINANCIAL_YEAR,
  IS_LOADING,
  IS_RESET,
  IS_DOWNLOADING,
  IS_COMPANY_ADDED,
  ADD_COMPANY_EMAIL,
} = actions;

const initState = {
  is_login: false,
  first_name: "",
  last_name: "",
  tocken: "",
  signup_name: "",
  bussness_name: "",
  post_sign_up: false,
  user_data: {},
  companyId: "",
  financialYear: 0,
  isLoading: false,
  isDownLoading: false,
  isCompanyAdded: false,
  addedCompanyEmail: "",
};

/**
 *
 * @todo impure state mutation/explaination
 */
const AuthReducer = (state = initState, action: any) => {
  const { type, data } = action;
  switch (type) {
    case SET_IS_LOGIN:
      return {
        ...state,
        is_login: data,
      };
    case SET_FISRT_NAME:
      return {
        ...state,
        first_name: data,
      };
    case SET_LAST_NAME:
      return {
        ...state,
        last_name: data,
      };
    case SET_USER_TOCKEN:
      return {
        ...state,
        tocken: data,
      };
    case SET_BUSSINESS_NAME:
      return {
        ...state,
        bussness_name: data,
      };
    case SET_SIGNUP_NAME:
      return {
        ...state,
        signup_name: data,
      };
    case SET_POST_SIGNUP:
      return {
        ...state,
        post_sign_up: data,
      };
    case SET_USER_DATA:
      return {
        ...state,
        user_data: data,
      };
    case SET_COMPANY_ID:
      return {
        ...state,
        companyId: data,
      };
    case SET_FINANCIAL_YEAR:
      return {
        ...state,
        financialYear: data,
      };
    case IS_LOADING:
      return {
        ...state,
        isLoading: data,
      };

    case IS_DOWNLOADING:
      return {
        ...state,
        isDownLoading: data,
      };
    case IS_RESET:
      return initState;

    case IS_COMPANY_ADDED:
      return {
        ...state,
        isCompanyAdded: data,
      };
    case ADD_COMPANY_EMAIL:
      return {
        ...state,
        addedCompanyEmail: data,
      };

    default:
      return state;
  }
};
export default AuthReducer;
