import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FetchLogin } from '../reducers/user/actionCreator';
interface Props {
	children: any;
	location?: any;
	FetchLogin?: any;
}
interface State {}
class Base extends Component<Props, State> {
	constructor(props: any) {
		super(props);
		this.state = {};
	}
	componentDidMount() {
		let id=localStorage.getItem(('company_id'))
		this.props.FetchLogin(id);
	}
	UpdateHeader() {
		// this.refs.headercom.GetUserCart();
		// this.refs.headercom.GetUserPrintCash();
	}
	render() {
		return (
			<div className="root">
				<noscript>
					<iframe
						title="tagmanager"
						src="https://www.googletagmanager.com/ns.html?id=GTM-5WPX5H"
						height="0"
						width="0"
						style={{ display: 'none', visibility: 'hidden' }}
					/>
				</noscript>
				{this.props.children}
			</div>
		);
	}
}
const mapStateToProps = (state: any) => ({
	//	...state
	companyId: state.user.companyId,

});
const mapDispatchToProps = (dispatch: any) => ({
	FetchLogin: () => dispatch(FetchLogin())
	// startAction: () => dispatch(startAction),
	// stopAction: () => dispatch(stopAction)
});

export default connect(mapStateToProps, mapDispatchToProps)(Base);
