import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Suspense, useEffect, useRef } from "react";
import "./App.css";
import Base from "./comman/Base";
import AuthForm from "./pages/AuthForm";
import Home from "./pages/Dashboard/Home";
import EmailVerify from "./pages/EmailVerify";
import Privacy from "./pages/Privacy";
import Tearms from "./pages/Tearms";
import ThankYou from "./pages/ThankYou";
import EmailAction from "./pages/EmailAction";
import BusinessForm from "./pages/BusinessForm";
import PersonalInfoForm from "./pages/PersonalInfoForm";
import ForgotPassword from "./pages/ForgotPassword";
import ForgotUpdatePassword from "./pages/ForgotUpdatePassword";
import ProfileAndBusinessUpdate from "./pages/ProfileAndBusinessUpdate";
import PasswordReset from "./pages/PasswordReset";
import SupportPage from "./pages/SupportPage";
import { connect } from "react-redux";
// @ts-ignore
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

const waitFor = (Tag: any) => (props: any) => <Tag {...props} />;

declare global {
  interface Window {
    heap: any;
  }
}

interface Props {
  first_name: string;
  companyName: string;
  email: string;
}

function App(Props: Props) {
  const tawkMessengerRef = useRef();

  //heap analytics integration
  useEffect(() => {
    window.heap = window.heap || {};
    window.heap?.load(process.env.REACT_APP_HEAP_APP_ID);
  }, []);

  useEffect(() => {
    window.heap?.loaded && window.heap?.identify(Props.email);
    window.heap?.loaded &&
      window.heap?.addUserProperties({
        CompanyName: Props.companyName,
      });

    //@ts-ignore
    if (tawkMessengerRef.current.onLoaded()) {
      // do something before loaded
      setAttributesTawk();
    }
  }, [JSON.stringify(window?.heap), Props.email, Props.companyName]);

  function setAttributesTawk() {
    //@ts-ignore
    tawkMessengerRef.current.setAttributes(
      {
        name: Props.first_name || "Visitor",
        email: Props.email || undefined,
        company: Props.companyName || "Company Name",
        // hash: "has value",
      },
      function (error: any) {
        // do something if error
        // console.log("tawk error", error);
      }
    );
  }

  const onTawkWidgetLoad = () => {
    setAttributesTawk();
  };

  return (
    <Suspense fallback={() => <p>loading....</p>}>
      <BrowserRouter basename="/clients/magikfin">
        {/* <Chat /> */}
        <TawkMessengerReact
          propertyId="62966b4ab0d10b6f3e74fdbb"
          widgetId="1g687g2rp"
          ref={tawkMessengerRef}
          onLoad={onTawkWidgetLoad}
        />
        <Switch>
          <Base>
            <Route key={1} exact path="/" component={waitFor(AuthForm)} />
            <Route key={2} exact path="/login" component={waitFor(AuthForm)} />
            <Route key={3} exact path="/privacy" component={waitFor(Privacy)} />
            <Route key={4} exact path="/terms" component={waitFor(Tearms)} />
            <Route key={5} exact path="/signup" component={waitFor(AuthForm)} />
            <Route
              key={6}
              exact
              path="/reset"
              component={waitFor(ForgotPassword)}
            />
            <Route
              key={7}
              exact
              path="/passwordupdate/:param"
              component={waitFor(ForgotUpdatePassword)}
            />
            <Route key={8} exact path="/dashboard" component={waitFor(Home)} />
            <Route key={9} exact path="/verify" component={EmailVerify} />
            <Route
              key={10}
              exact
              path="/thankyou/:param"
              component={ThankYou}
            />
            <Route
              key={11}
              exact
              path="/business"
              component={waitFor(BusinessForm)}
            />
            {/* <Route
              key={10}
              exact
              path="/acountsetup"
              component={waitFor(GstInfo)}
            /> */}
            <Route
              key={12}
              exact
              path="/info"
              component={waitFor(PersonalInfoForm)}
            />
            <Route
              key={17}
              exact
              path="/addcompany"
              component={waitFor(PersonalInfoForm)}
            />
            <Route
              key={13}
              exact
              path="/profile"
              component={waitFor(ProfileAndBusinessUpdate)}
            />
            <Route
              key={14}
              exact
              path="/changepassword"
              component={waitFor(PasswordReset)}
            />{" "}
            <Route
              key={15}
              exact
              path="/support"
              component={waitFor(SupportPage)}
            />
            <Route
              key={16}
              exact
              path="/emailAction/:param"
              component={EmailAction}
            />
          </Base>
        </Switch>
      </BrowserRouter>
    </Suspense>
  );
}
const mapStateToProps = (state: any) => {
  return {
    companyName: state?.user?.bussness_name,
    email: state?.user?.user_data?.data?.email,
    first_name: state?.user?.user_data?.data?.first_name,
  };
};
const mapDispatchToProps = (dispatch: any) => ({
  // startAction: () => dispatch(startAction),
  // stopAction: () => dispatch(stopAction)
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
