import React, { useState } from "react";
import "../styles/home.css";
import InputFIeld from "../components/InputFIeld";
import "../styles/newPassword.css";
import  "../styles/updatePassword.css"

import Navbar from "../components/Navbar";
import { connect } from "react-redux";
import API from "../Helper/Api";
import swal from "sweetalert";
import { Button } from "@mui/material";
import vector from '../images/Vector.png'
import semicircle from '../images/semi-circle.png';
import greencircle from '../images/green-circle.png';
import linecircle from '../images/line-shape.png'
import spark from '../images/shape.png'
interface Props {
  location: any;
  history: any;
  first_name: string;
  last_name: string;
  bussness_name: string;
}
const PassordReset = (Props: Props) => {
  const [errorMessages, seterrorMessages] = useState<Array<any>>([]);
  const [password, setpassword] = useState("");
  const [cnf_password, setcnf_password] = useState("");
  const ShowError = (name: string) => {
    return errorMessages.filter((x) => x.name === name).length > 0;
  };
  const GetMessage = (name: string) => {
    return errorMessages.filter((x) => x.name === name)[0].message;
  };
  const SubmitFrom = () => {
    let tocken = localStorage.getItem("@tocken");
    const AuthStr = "Bearer ".concat(tocken || "");
    seterrorMessages([]);
    API.patch(
      `user/updatepassword`,
      {
        password: password,
        cnf_password: cnf_password,
      },
      {
        headers: { Authorization: AuthStr },
      }
    )
      .then((data) => {
        if (data.data.status) {
          swal({
            title: "Success",
            text:data.data.message,
          }).then((res) => {
            if(res)
            window.localStorage.removeItem('fg_password')
            Props.history.push("/login")
          })
        } else {
          if (data.data.valData) {
            let dataerrer = data.data.valData.errors.map(
              (item: any, index: number) => {
                return { name: item.param, message: item.msg };
              }
            );
            seterrorMessages(dataerrer);

            // console.log(dataerrer);
          } else {
            let item = { name: "all", message: data.data.message };
            seterrorMessages([item]);
          }
        }
      })
      .catch((error) => {});
  };
  return (
    <div
      className="home-container"
      style={{
        height: "100%",
      }}
      >
      <Navbar history={Props.history} />
      <div className="newpassword">
      <img src={semicircle} alt="" className="semi-circle"/>
        <img src={greencircle} alt="" className="green-cricle"/>
        <img src={linecircle} alt="" className="line-shape"/>
        <img src={spark} alt="" className="forgetPassSparks" />
        <div className="resetPassword">
        <div className="main__head">
          <p className="main__head-profile">Setup new password</p>
          {/* <p className="main__head-name">
            Hello {Props.first_name} {Props.last_name} !
          </p>
          <p className="main__head-mark">{Props.bussness_name}</p> */}
        </div>
{/* 
        <div className="main__tab">
          <button>change password</button>
        </div> */}
        <div>
        <form className="main__changepass-form">
          <div>
          <div className="form-control inputHeight">
            <div className="main__inputbox1">
              <InputFIeld
                type={"password"}
                placeholder="Enter new password"
                disable={false}
                showError={true}
                value={password}
                onChange={(e: any) => {
                  setpassword(e.target.value);
                }}
              />
            </div>
            {ShowError("password") ? (
              <span className="tooltiptext">{GetMessage("password")}</span>
            ) : null}
          </div>
          <div className="form-control inputHeight">
            <div className="main__inputbox1">
              <InputFIeld
                type={"password"}
                placeholder="Re-enter new password"
                showError={true}
                disable={false}
                value={cnf_password}
                onChange={(e: any) => {
                  setcnf_password(e.target.value);
                }}
              />
            </div>
            {ShowError("cnf_password") ? (
              <span className="tooltiptext">{GetMessage("cnf_password")}</span>
            ) : null}
          </div>
          </div>
          <Button
            variant="contained"
            onClick={() => {
              SubmitFrom();
            }}
            className="profile-btn updatePassword"
          >
            Update password  <img src={vector}></img>
          </Button>
          {/* <button
            type="button"
            onClick={() => {
              SubmitFrom();
            }}
          >
            Update
          </button> */}
        </form>
        </div>
        </div>  
      </div>
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  first_name: state.user.first_name,
  last_name: state.user.last_name,
  bussness_name: state.user.bussness_name,
});
const mapDispatchToProps = (dispatch: any) => ({
  // startAction: () => dispatch(startAction),
  // stopAction: () => dispatch(stopAction)
});

export default connect(mapStateToProps, mapDispatchToProps)(PassordReset);
