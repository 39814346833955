import Logo from "../images/MF.png";
import "../styles/authForm.css";
const SidebarInfo = () => {
  return (
    <div className="left lef-panel">
      {/* <img
				src={Logo}
				alt="mf logo"
				style={{
					width: '130px',
					height: '50px',
					objectFit: 'cover'
				}}
			/> */}

      {/* <img
        src={illustration}
        alt=""
        style={{
          width: "80%",
        }}
      /> */}

      {/* <div className="left__info">
        <p>How MagikFin can help you?</p>
        <div className="left__info-content">
          <p>
            - None of our clients pays penalties or interest for any
            non-compliance
          </p>
          <p>
            - First Let us help you manage your compliances by sending you
            timely reminders
          </p>
          <p>
            - We focus on your Compliances so you can focus on your business
          </p>
        </div>
      </div> */}
      <div className="complaine-text">
        Save Time and Money by automating your Compliance.
      </div>
      <div className="complaine-sub-text">
        You can manage all your compliance like GST, TDS, PF, ESI etc. at one
        place, that can save hundreds of hours every year.
      </div>
      {/* <div className="left__bottom">
        <p
          onClick={() => {
            window.location.href = "/privacy";
          }}
        >
          Privacy Policy
        </p>
        <p
          onClick={() => {
            window.location.href = "/terms";
          }}
        >
          Terms of Use
        </p>
      </div> */}
    </div>
  );
};

export default SidebarInfo;
