import React, { useState } from "react";
import "../styles/authForm.css";
import SidebarInfo from "../components/SidebarInfo";
import PasswordResetForm from "../components/PasswordResetForm";
import Logo from "../images/MF.png";
import semicircle from '../images/semi-circle.png';
import greencircle from '../images/green-circle.png';
import linecircle from '../images/line-shape.png'
import spark from '../images/shape.png'
interface Props {
  history: any;
  location: any;
}
const ForgotPassword = (Props: Props) => {
  // console.log(Props)
  const [showPass, setShowPass] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  return (
    <div className="login-auth">
      <div className="auth-Header">
        <div className="logo-icon">
          <img src={Logo} alt="mf logo" />
        </div>
      </div>
      {/* <SidebarInfo /> */}
      <div className="right  user-page forgot-password">
      <img src={semicircle} alt="" className="semi-circle"/>
        <img src={greencircle} alt="" className="green-cricle"/>
        <img src={linecircle} alt="" className="line-shape"/>
        <img src={spark} alt="" className='forgetPassSparks center'/>
        <PasswordResetForm
          showPass={showPass}
          setShowLogin={setShowLogin}
          showLogin={showLogin}
          setShowPass={setShowPass}
          onresetpress={() => {
            Props.history.push("/login");
          }}
          onpress={() => {
            //Page redirected to /login instead of /changepassword after resetting password
            Props.history.push("/login");
          }}
        />
      </div>
    </div>
  );
};

export default ForgotPassword;
