import React, { useEffect, useState } from "react";

import API from "../Helper/Api";
import VerifyPage from "../components/VerifyPage";
interface Props {
  history: any;
  match: any;
}
const ThankYou = (Props: Props) => {
  const [message, setmessage] = useState("Checking");
  const [message1, setmessage1] = useState("Checking");
  useEffect(() => {
    if (Props.match.params.param && Props.match.params.param.length > 0) {
      SubmitFrom(Props.match.params.param);
    }
    return () => {};
  }, []);
  const SubmitFrom = (codestr: string) => {
    API.patch(`user/account/active`, {
      code: codestr,
    })
      .then((data) => {
        if (data.data.status) {
          setmessage("Thank you for submitting your details.");
          setmessage1("Your Compliance Calendar will be available soon");
          setTimeout(() => {
            if (data.data.verified) {
              Props.history.push("/login");
            } else {
              Props.history.push("/login");
            }
          }, 2000);
          localStorage.setItem("@tocken", data.data.data.token);
        } else {
          setmessage("Your verification code expired");
          setmessage1("Your verification code expired");
        }
      })
      .catch((error) => {});
  };
  return (
    <VerifyPage
      text1={message}
      text2={message1}
      resendText=""
      isResend={false}
      onpress={() => {}}
    />
  );
};

export default ThankYou;
