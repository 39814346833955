import actions from "./actions";
import Api from "../../Helper/Api";

const {
  SetData,
  SetfirstName,
  SetlastName,
  SetUserTocken,
  SetBussinessName,
  SetsignupName,
  SetPostSignup,
  SetUserData,
  SetCompanyId,
  SetFinancialYear,
  setLoading,
  reset,
  setDownload,
  setCompanyAdded,
  setAddCompanyEmail,
} = actions;

const FetchLogin = (companyId = "") => {
  return async (dispatch: any, getState: any) => {
    try {
      if (localStorage.hasOwnProperty("@tocken")) {
        //console.log("useeeffect navbar 2");
        let tocken = localStorage.getItem("@tocken");
        if (tocken) {
          //console.log("useeeffect navbar 3");
          const AuthStr = "Bearer ".concat(tocken ? tocken : "");
          let id = companyId
            ? companyId
            : localStorage.getItem("company_id") ?? getState().user.companyId;
          Api.get(`user/me`, {
            headers: { Authorization: AuthStr, companyId: id ?? "" },
          })
            .then((data) => {
              if (data.data.status) {
                dispatch(SetData(true));
                dispatch(SetCompanyId(data.data.bussinessdata?.id));
                localStorage.setItem("company_id", data.data.bussinessdata?.id);
                dispatch(SetUserData(data.data));
                dispatch(SetUserTocken(tocken));
                dispatch(SetfirstName(data.data.data.first_name));
                dispatch(SetlastName(data.data.data.last_name));
                if (data.data.bussinessdata) {
                  dispatch(SetBussinessName(data.data.bussinessdata.name));
                } else {
                  dispatch(SetBussinessName(""));
                }
              } else {
                dispatch(SetData(false));
              }
              // console.log("me data", data.data.data);
            })
            .catch((error) => {
              dispatch(SetData(false));
            });
        } else {
          // console.log("useeeffect navbar 4");
          dispatch(SetData(false));
        }
      } else {
        // console.warning("====================================================================App crash");
        dispatch(SetData(false));
      }
    } catch (err) {
      // console.log("useeeffect navbar 6");
      dispatch(SetData(false));
    }
  };
};

const SetLoginTocken = (name: string) => {
  return (dispatch: any) => {
    dispatch(SetUserTocken(name));
  };
};
const SetSignupName = (name: string) => {
  return (dispatch: any) => {
    dispatch(SetsignupName(name));
  };
};

const SetSignUpPost = (val: boolean) => {
  return (dispatch: any) => {
    dispatch(SetPostSignup(val));
  };
};

const SetUser = (userDetails: any) => {
  return (dispatch: any) => {
    dispatch(SetUserData(userDetails));
  };
};

const SetCompId = (data: any) => {
  return (dispatch: any) => {
    dispatch(SetCompanyId(data));
  };
};

const SetFinYear = (data: any) => {
  return (dispatch: any) => {
    dispatch(SetFinancialYear(data));
  };
};

const fileActions = (data: any) => {
  return (dispatch: any) => {
    dispatch(setLoading(data));
  };
};

const deleteDetails = () => {
  return (dispatch: any) => {
    dispatch(reset());
  };
};

const downloadActions = (data: any) => {
  return (dispatch: any) => {
    dispatch(setDownload(data));
  };
};

const setIsCompanyAdded = (data: any) => {
  return (dispatch: any) => {
    dispatch(setCompanyAdded(data));
  };
};
const setAddedCompanyEmail = (data: any) => {
  return (dispatch: any) => {
    dispatch(setAddCompanyEmail(data));
  };
};

export {
  FetchLogin,
  SetLoginTocken,
  SetSignupName,
  SetSignUpPost,
  SetUser,
  SetCompId,
  SetFinYear,
  fileActions,
  deleteDetails,
  downloadActions,
  setIsCompanyAdded,
  setAddedCompanyEmail,
};
