import React, { useEffect, useState } from "react";
import SidebarInfo from "../components/SidebarInfo";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import "../styles/businessForm.css";
import API from "../Helper/Api";
import { MdInfoOutline } from "react-icons/md";
import { Tooltip } from "@material-ui/core";

interface Props {
  history: any;
}
const BusinessForm = (Props: Props) => {
  const [errorMessages, seterrorMessages] = useState<Array<any>>([]);
  const [emp, setemp] = useState([]);
  const [empIndex, setEmpIndex] = useState("");
  const [TypeBussiness, setTypeBussiness] = useState([]);
  const [TypeBussinessIndex, setTypeBussinessIndex] = useState("");
  const [Activity, setActivity] = useState([]);
  const [ActivityIndex, setActivityIndex] = useState("");
  const [salary, setsalary] = useState([]);
  const [salaryIndex, setSalaryIndex] = useState("");
  const [name, setname] = useState("");
  const [address, setaddress] = useState("");
  const [city, setcity] = useState("");
  const [state, setstate] = useState("");
  const [other_branch, setother_branch] = useState([]);
  useEffect(() => {
    LoadData();
    return () => {};
  }, []);
  const ShowError = (name: string) => {
    return errorMessages.filter((x) => x.name === name).length > 0;
  };
  const GetMessage = (name: string) => {
    return errorMessages.filter((x) => x.name === name)[0].message;
  };
  const LoadData = () => {
    API.get(`bussinesstype/getall/active`)
      .then((data) => {
        setTypeBussiness(data.data.data.filter((element: any) => element.title !== "all"));
      })
      .catch((error) => {});
    API.get(`bussinessactivity/getall/active`)
      .then((data) => {
        setActivity(data.data.data.filter((element: any) => element.title !== "all"));
      })
      .catch((error) => {});
    API.get(`bussinessrevenue/getall/active`)
      .then((data) => {
        setsalary(data.data.data.filter((element: any) => element.title !== "all"));
      })
      .catch((error) => {});
    API.get(`bussinessemployees/getall/active`)
      .then((data) => {
        setemp(data.data.data.filter((element: any) => element.title !== "all"));
      })
      .catch((error) => {});
  };
  const SubmitFrom = () => {
    let tocken = localStorage.getItem("@tocken");
    const AuthStr = "Bearer ".concat(tocken || "");
    seterrorMessages([]);
    API.patch(
      `user/signup/createbussinessinfo`,
      {
        name: name,
        address: address,
        city: city,
        state: state,
        other_branch: other_branch.map((item: any, index: number) => {
          return item.value;
        }),
        typeofbussiness: TypeBussinessIndex,
        activity: ActivityIndex,
        employees: empIndex,
        revenue: salaryIndex,
      },
      {
        headers: { Authorization: AuthStr },
      }
    )
      .then((data) => {
        if (data.data.status) {
          Props.history.push("/verify");
        } else {
          if (data.data.valData) {
            let dataerrer = data.data.valData.errors.map(
              (item: any, index: number) => {
                return { name: item.param, message: item.msg };
              }
            );
            seterrorMessages(dataerrer);

            // console.log(dataerrer);
          } else {
            let item = { name: "all", message: data.data.message };
            seterrorMessages([item]);
          }
        }
      })
      .catch((error) => {});
  };
  const handleChange = (newValue: any, actionMeta: any) => {
    console.group("Value Changed");
    // console.log(newValue);
    setother_branch(newValue);
    // console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
  };
  const handleInputChange = (inputValue: any, actionMeta: any) => {};
  return (
    <div className="auth-container">
      <SidebarInfo />
      <div className="right">
        <div className="form-container">
          <form className="business_form">
            <p
              style={{
                textAlign: "center",
                marginBottom: "6px",
                fontSize: "15px",
                fontWeight: 600,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              // className="form-head-label"
            >
              <span
                style={{
                  width: "77%",
                }}
              >
                Business Details
              </span>
              <span>2/2</span>
            </p>
            <div className="input__box">
              <input
                placeholder="Registered Name of Business"
                required
                type="text"
                value={name}
                onChange={(e: any) => {
                  setname(e.target.value);
                }}
              />
              <span className="input-required">
                <Tooltip
                  title="Registered Business Address"
                  placement="right-start"
                  arrow
                >
                  <p>
                    <MdInfoOutline
                      size={16}
                      color="#000"
                      style={{
                        marginRight: "5px",
                        color: "#000",
                      }}
                    />
                  </p>
                </Tooltip>
                <span className="small-required">*</span>
              </span>
            </div>
            {ShowError("name") ? (
              <span className="tooltiptext">{GetMessage("name")}</span>
            ) : null}
            <div className="multi__form">
              <p className="form-head-label">
                Registered Business Address{" "}
                <Tooltip
                  title="Registered Business Address"
                  placement="right-start"
                  arrow
                >
                  <p className="small-text">
                    <MdInfoOutline
                      size={16}
                      style={{
                        marginLeft: "5px",
                      }}
                    />
                  </p>
                </Tooltip>
              </p>
              <div className="input__box">
                <input
                  placeholder="Registered Business Address"
                  required
                  type="text"
                  value={address}
                  onChange={(e: any) => {
                    setaddress(e.target.value);
                  }}
                />
                <span className="input-required">*</span>
              </div>
              {ShowError("address") ? (
                <span className="tooltiptext">{GetMessage("address")}</span>
              ) : null}
              <p
                style={
                  {
                    // marginTop:"15px"
                  }
                }
                className="form-head-label"
              >
                Registered Business Address
                <Tooltip
                  title="Registered Business Address"
                  placement="right-start"
                  arrow
                >
                  <p>
                    <MdInfoOutline
                      size={16}
                      color="#000"
                      style={{
                        marginLeft: "5px",
                        color: "#000",
                      }}
                    />
                  </p>
                </Tooltip>
              </p>
              <div className="multi_field">
                <div className="input__box">
                  <input
                    placeholder="City"
                    required
                    type="text"
                    value={city}
                    onChange={(e: any) => {
                      setcity(e.target.value);
                    }}
                  />
                  <span className="input-required">*</span>
                </div>

                <div className="input__box">
                  <input
                    placeholder="State"
                    required
                    type="text"
                    value={state}
                    onChange={(e: any) => {
                      setstate(e.target.value);
                    }}
                  />
                  <span className="input-required">*</span>
                </div>
              </div>
              {ShowError("city") ? (
                <span className="tooltiptext">
                  {GetMessage("city")}
                  <br />
                </span>
              ) : null}

              {ShowError("state") ? (
                <span className="tooltiptext">{GetMessage("state")}</span>
              ) : null}
              <p
                style={{
                  marginTop: "10px",
                }}
                className="form-head-label"
              >
                Address of other Branches
              </p>
              <div className="input__box">
                <CreatableSelect
                  placeholder=""
                  className="multi-select"
                  name="branches"
                  options={[
                    {
                      value: "Delhi",
                      label: "Delhi",
                    },
                    {
                      value: "Maharashtra",
                      label: "Maharashtra",
                    },
                  ]}
                  value={other_branch}
                  onChange={handleChange}
                  isMulti
                  isClearable
                />
              </div>
              {ShowError("other_branch") ? (
                <span className="tooltiptext">
                  {GetMessage("other_branch")}
                </span>
              ) : null}
            </div>
            <p className="form-head-label">Registered Business Details</p>
            <p className="form-head-label">
              Type of Business
              <span className="input-required small-required">
                *
                <Tooltip
                  title="Registered Business Address"
                  placement="right-start"
                  arrow
                >
                  <p>
                    <MdInfoOutline
                      size={16}
                      color="#000"
                      style={{
                        marginLeft: "5px",
                        color: "#000",
                      }}
                    />
                  </p>
                </Tooltip>
              </span>
            </p>
            {ShowError("typeofbussiness") ? (
              <span className="tooltiptext">
                {GetMessage("typeofbussiness")}
              </span>
            ) : null}
            <div className="form__multi-btns">
              {TypeBussiness.map((item: any, index: number) => {
                return (
                  <div
                    key={index}
                    onClick={() => {
                      setTypeBussinessIndex(item.id);
                      setActivityIndex("");
                      setEmpIndex("");
                      setSalaryIndex("");
                    }}
                    className={
                      TypeBussinessIndex === item.id ? "selected-emp" : ""
                    }
                  >
                    {item.title}
                  </div>
                );
              })}
            </div>
            <p className="form-head-label">
              Main business activity
              <span className="input-required small-required">
                *
                <Tooltip
                  title="Registered Business Address"
                  placement="right-start"
                  arrow
                >
                  <p>
                    <MdInfoOutline
                      size={16}
                      color="#000"
                      style={{
                        marginLeft: "5px",
                        color: "#000",
                      }}
                    />
                  </p>
                </Tooltip>
              </span>
            </p>
            {ShowError("activity") ? (
              <span className="tooltiptext">{GetMessage("activity")}</span>
            ) : null}
            <div className="form__multi-btns">
              {Activity.filter(
                (x: any) => x.bussiness_type_id == TypeBussinessIndex
              ).map((item: any, index: number) => {
                return (
                  <div
                    key={index}
                    onClick={() => setActivityIndex(item.id)}
                    className={ActivityIndex === item.id ? "selected-emp" : ""}
                  >
                    {item.title}
                  </div>
                );
              })}
            </div>
            <p className="form-head-label">
              Number of Employees
              <span className="input-required small-required">
                *
                <Tooltip
                  title="Registered Business Address"
                  placement="right-start"
                  arrow
                >
                  <p>
                    <MdInfoOutline
                      size={16}
                      color="#000"
                      style={{
                        marginLeft: "5px",
                        color: "#000",
                      }}
                    />
                  </p>
                </Tooltip>
              </span>
            </p>
            {ShowError("employees") ? (
              <span className="tooltiptext">{GetMessage("employees")}</span>
            ) : null}
            <div className="form__multi-btns">
              {emp
                .filter((x: any) => x.bussiness_type_id == TypeBussinessIndex)
                .map((item: any, index: number) => {
                  return (
                    <div
                      key={index}
                      onClick={() => setEmpIndex(item.id)}
                      className={empIndex === item.id ? "selected-emp" : ""}
                    >
                      {item.title}
                    </div>
                  );
                })}
            </div>
            <p className="form-head-label">
              Last year Revenue
              <span className="input-required small-required">
                *
                <Tooltip
                  title="Registered Business Address"
                  placement="right-start"
                  arrow
                >
                  <p>
                    <MdInfoOutline
                      size={16}
                      color="#000"
                      style={{
                        marginLeft: "5px",
                        color: "#000",
                      }}
                    />
                  </p>
                </Tooltip>
              </span>
            </p>
            {ShowError("revenue") ? (
              <span className="tooltiptext">{GetMessage("revenue")}</span>
            ) : null}
            <div className="form__multi-btns">
              {salary
                .filter((x: any) => x.bussiness_type_id == TypeBussinessIndex)
                .map((item: any, index: number) => {
                  return (
                    <div
                      key={index}
                      onClick={() => setSalaryIndex(item.id)}
                      className={salaryIndex === item.id ? "selected-emp" : ""}
                    >
                      {item.title}
                    </div>
                  );
                })}
            </div>
            <button
              type="button"
              onClick={() => {
                SubmitFrom();
              }}
              className="business_form-submit-btn"
            >
              submit
            </button>
            <p
              style={{
                textAlign: "center",
                marginTop: "20px",
                fontSize: "13px",
                color: "#AEAEAE",
              }}
            >
              ©️ Cuentas Technologies Pvt Ltd
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BusinessForm;
