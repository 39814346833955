import React from "react";
import "../styles/businessForm.css";
import verified from "../images/checked.png";
import { TextField } from "@mui/material";
interface Props {
  placeholder: string;
  showError: boolean;
  showSuccess?: boolean;
  hideIcon?: boolean;
  type?: string;
  value: any;
  onChange: any;
  disable: any;
  fieldType?: string;
}
const InputFIeld = (Props: Props) => {
  return (
    <div className="input__box111">
      {Props.fieldType ? (
        <textarea
          placeholder={Props.placeholder}
          value={Props.value}
          onChange={Props.onChange}
        />
      ) : (
        <TextField
          placeholder={Props.placeholder}
          label={Props.placeholder}
          //required
          type={Props.type ? Props.type : "text"}
          value={Props.value}
          disabled={Props.disable}
          onChange={Props.onChange}
          variant="outlined"
          className="input-field"
        />
      )}

      {/* {Props.showError && <span className="input-required">*</span>} */}
      {Props.showSuccess && (
        <img
          src={verified}
          style={{
            width: "18px",
            height: "18px",
          }}
          alt="verified"
          className="input-required"
        />
      )}
    </div>
  );
};

export default InputFIeld;
