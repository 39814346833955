import { useEffect, useState } from "react";
import InputFIeld from "../components/InputFIeld";
import "../styles/PersonalForm.css";
import MyJson from "../Helper/india_state_city.json";
import API, { path } from "../Helper/Api";
import { connect, useDispatch } from "react-redux";
import {
  setAddedCompanyEmail,
  setIsCompanyAdded,
  SetSignupName,
  SetSignUpPost,
} from "../reducers/user/actionCreator";
import Logo from "../images/MF.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Autocomplete,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Select from "@mui/material/Select";
import semicircle from "../images/semi-circle.png";
import greencircle from "../images/green-circle.png";
import linecircle from "../images/line-shape.png";
import spark from "../images/shape.png";
import swal from "sweetalert";
import { useLocation } from "react-router-dom";

interface Props {
  history: any;
  signup_name: string;
  SetSignupName: any;
  emaildata: any;
  post_sign_up: boolean;
  SetSignUpPost: any;
  companyId: string;
  isCompanyAdded: boolean;
  addedCompanyEmail: string;
}
const PersonalInfoForm = (Props: Props) => {
  const isInvited = localStorage.getItem("isInvited");
  const isProfileCompleted = JSON.parse(
    //@ts-ignore
    localStorage.getItem("isProfileCompleted")
  );
  const isAssociatedToCompany = JSON.parse(
    //@ts-ignore
    localStorage.getItem("isAssociatedToCompany")
  );
  const manualDispatch = useDispatch();
  const location = useLocation();
  // console.log("Props.isCompanyAdded: ", Props.isCompanyAdded);

  if (location.pathname === "/addcompany") {
    manualDispatch(setIsCompanyAdded(true));
    const addedCompanyEmail = localStorage.getItem("AddedCompanyEmail");
    manualDispatch(setAddedCompanyEmail(addedCompanyEmail));
    // console.log("isCompanyAdded: ", Props.isCompanyAdded);
  } else {
    // not required these variables for /info path(sign up form )
    localStorage.removeItem("isCompanyAdded");
    localStorage.removeItem("AddedCompanyEmail");
    // console.log("other path");
  }

  const [errorMessages, seterrorMessages] = useState<Array<any>>([]);
  const [first_name, setfirst_name] = useState("");
  const [page, setPage] = useState(() => {
    let flag;
    if (isInvited == "true" || (isAssociatedToCompany && !isProfileCompleted)) {
      return 4;
    } else {
      return 1;
    }
  });

  useEffect(() => {
    let flag;
    if (isInvited == "true" || (isAssociatedToCompany && !isProfileCompleted)) {
      flag = 4;
    } else {
      flag = 1;
    }
    setPage(flag);
  }, [isInvited, isAssociatedToCompany, isProfileCompleted]);

  const [last_name, setlast_name] = useState("");
  const [phone, setphone] = useState("");
  const [phonecode, setphonecode] = useState("+91");
  const [emp, setemp] = useState([]);
  const [empIndex, setEmpIndex] = useState("");
  const [TypeBussiness, setTypeBussiness] = useState([]);
  const [TypeBussinessIndex, setTypeBussinessIndex] = useState<any>("");
  const [Activity, setActivity] = useState([]);
  const [ActivityIndex, setActivityIndex] = useState("");
  const [salary, setsalary] = useState([]);
  const [salaryIndex, setSalaryIndex] = useState("");
  const [name, setname] = useState("");
  const [address, setaddress] = useState("");
  const [city, setcity] = useState("");
  const [state, setstate] = useState("");
  const [other_branch, setother_branch] = useState([]);
  const [gstNumber, setGstData] = useState("");
  const [gstError, setGstError] = useState<any>("");
  const [businesname, setbusinessName] = useState<any>("");
  const [gstData, setGSTCurentData] = useState<any>("");
  const [gstSkip, setGSTSkip] = useState<boolean>(false);
  const [email_id, setemail] = useState<any>("");
  const [skipPage, setSkippage] = useState<any>(1);
  const [gstNumbertext, setGSTNumber] = useState<any>(0);
  const [gstState, setGstState] = useState("");
  const [bussinessActivity, setBusinessActivity] = useState<any>("");
  const [finalSubmit, setFinaSubmit] = useState(false);
  const [isAddedUserExist, setIsAddedUserExist] = useState(false);

  const statedata = [...new Set(MyJson.map((item) => item.state))];
  const cityDatawithoutSkip = [...new Set(MyJson.map((item) => item.name))];
  let findArressSate = "";
  let findArryCity = "";
  let errorData: any = [];
  statedata.some((element) => {
    if (address.includes(element)) {
      findArressSate = element;
    }
  });

  const cityData = [
    ...new Set(
      MyJson.map(({ name, state }) => JSON.stringify({ name, state }))
    ),
  ].map((json) => JSON.parse(json));

  //sorting cityData array
  cityData.sort((a, b) => {
    let cityA = a.name.toLowerCase(),
      cityB = b.name.toLowerCase();

    if (cityA < cityB) {
      return -1;
    }
    if (cityA > cityB) {
      return 1;
    }
    return 0;
  });

  const cityName = [
    ...new Set(
      cityData.map((item) => {
        return item.name;
      })
    ),
  ];

  cityDatawithoutSkip.some((element: any) => {
    if (address.includes(element)) {
      findArryCity = element;
    }
  });

  useEffect(() => {
    // if company added ==> /addcompany
    if (Props.isCompanyAdded) {
      checkAddCompanyEmail();
    } else {
      // if sign up ==> /info
      let tocken = localStorage.getItem("@tocken");
      if (tocken) {
        const AuthStr = "Bearer ".concat(tocken ? tocken : "");
        // let id=localStorage.getItem(JSON.parse('company_id'))
        let id = Props.companyId;
        API.get(`user/me`, {
          headers: { Authorization: AuthStr, companyId: id },
        }).then((data) => {
          if (data.data.status) {
            setemail(data.data.data?.email?.toLowerCase());
          }
        });
      }
    }
    LoadData();
    // let flag
    // if(isInvited =='true'){
    //   flag= 4
    // }else{
    // flag= 1

    // }
    // setPage(flag)
    return () => {};
  }, []);

  const checkAddCompanyEmail = async () => {
    const token = localStorage.getItem("@tocken");
    const AuthStr = "Bearer ".concat(token || "");
    const headers = {
      Authorization: AuthStr,
      "Content-Type": "application/json",
    };
    const payload = {
      email: Props.addedCompanyEmail?.toLowerCase(),
    };
    try {
      const response = await fetch(`${path()}/user/getUserDetails`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(payload),
      });
      const result = await response.json();
      if (result.status) {
        // console.log("getUserDetail: ", result);
        setIsAddedUserExist(result?.isUserExists);
        setfirst_name(result?.data?.first_name);
        setlast_name(result?.data?.last_name);
        setemail(Props.addedCompanyEmail?.toLowerCase());
        setphone(result?.data?.phone);
        // setphonecode(result?.data?.phonecode ?? "+91");
      }
    } catch (error: any) {
      const errorData = error.response.data;
      if (errorData) {
        if (errorData.valData) {
          let dataerrer = errorData.valData.errors.map(
            (item: any, index: number) => {
              return { name: item.param, message: item.msg };
            }
          );
          seterrorMessages(dataerrer);
        } else {
          let item = { name: "all", message: errorData.message };
          seterrorMessages([item]);
        }
      }
    }
  };

  const LoadData = () => {
    API.get(`bussinesstype/getall/active`)
      .then((data) => {
        // data.data.data.splice(0, 0, { title: "Select Type of Business" });
        setTypeBussiness(
          data.data.data.filter((element: any) => element.title !== "all")
        );
        // console.log('Business Type: ',data.data.data);
      })
      .catch((error) => {});
    API.get(`bussinessactivity/getall/active`)
      .then((data) => {
        // data.data.data.splice(0, 0, { title: "Select Type of Activity" });
        setActivity(
          data.data.data.filter((element: any) => element.title !== "all")
        );
      })
      .catch((error) => {});
    API.get(`bussinessrevenue/getall/active`)
      .then((data) => {
        setsalary(
          data.data.data.filter((element: any) => element.title !== "all")
        );
      })
      .catch((error) => {});
    API.get(`bussinessemployees/getall/active`)
      .then((data) => {
        setemp(
          data.data.data.filter((element: any) => element.title !== "all")
        );
      })
      .catch((error) => {});
    const userName = Props.signup_name.split(" ");

    setfirst_name(
      userName.length > 1
        ? userName.slice(0, userName.length - 1).join(" ")
        : Props.signup_name
    );
    setlast_name(userName.length > 1 ? userName[userName.length - 1] : "");
  };
  // console.log("emp: ", emp);
  // console.log("type", TypeBussiness);

  //Select City
  function selectCity(addressArray: any, cityJurisdiction: any) {
    let city = addressArray[addressArray.length - 3].trim();
    // console.log("city orginal: ", city);
    city = city.toLowerCase().includes("bengaluru") ? "Bangalore" : city;
    if (!cityName.includes(city)) {
      // If city name is of two or more words, check if first word is part of cityName(eg: Bengaluru Urban) otherwise take second word(like in North Goa)
      city.split(" ").forEach((cityWord: any) => {
        if (cityName.includes(cityWord)) {
          // console.log('citySplit:', cityWord)
          city = cityWord;
        }
      });

      if (cityName.includes(city)) {
        return city;
      }
    } else {
      return city;
    }
    //Convert 'UPPERCASE' city name to 'Title Case'
    let city3rdFromLast = addressArray[addressArray.length - 4].trim();
    city3rdFromLast =
      city3rdFromLast.charAt(0).toUpperCase() +
      city3rdFromLast.slice(1).toLowerCase();
    // console.log("city3rdFromLast", city3rdFromLast);
    if (cityName.includes(city3rdFromLast)) {
      //if second last word of address and its split is not there in cityName drop down list, select 3rd word from the last
      return city3rdFromLast;
    }
    //If above city names still not available in JSON drop down list, select city from center_jurisdiction - Commissionerate
    //Convert 'UPPERCASE' city name to 'Title Case'
    city =
      cityJurisdiction[0].toUpperCase() +
      cityJurisdiction.slice(1).toLowerCase();
    // console.log("city jurisdiction: ", city);
    return cityName.includes(city) ? city : "";
  }

  const checkUniqueGST = (gst: any) => {
    let tocken = localStorage.getItem("@tocken");
    const AuthStr = "Bearer ".concat(tocken || "");

    API.get("user/getCompanyDetails", {
      headers: { Authorization: AuthStr, gst },
    }).then((res) => {
      if (!res.data.isCompanyExists) {
        getGSTInfo(gst);
      } else {
        swal("Error!", res.data.message, "error");
        setGstError({ message: res.data.message });
      }
    });
  };

  const getGSTInfo = async (gst: any) => {
    const token =
      "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTY1Mjc4NDEyNywianRpIjoiYmU4Y2IxOTEtMDJhMi00YjliLWE4MjItMjQ4ZjA4YjQzYmQyIiwidHlwZSI6ImFjY2VzcyIsImlkZW50aXR5IjoiZGV2Lm1hZ2lrZmluQGFhZGhhYXJhcGkuaW8iLCJuYmYiOjE2NTI3ODQxMjcsImV4cCI6MTk2ODE0NDEyNywidXNlcl9jbGFpbXMiOnsic2NvcGVzIjpbInJlYWQiXX19.2eNc-lnsxi9roNdsYgYmPWzvzb06WtUjlbXMkZugSF0";

    let headers: any = { "Content-Type": "application/json" };
    if (token) {
      headers["Authorization"] = `${token}`;
    }
    const requestOptions = {
      method: "POST",
      headers,
      body: JSON.stringify({ id_number: gst }),
    };
    await fetch(
      "https://kyc-api.surepass.io/api/v1/corporate/gstin",
      requestOptions
    )
      .then((res) => res.json())
      .then((res) => {
        setGstError({ message: "PLEASE ENTER CORRECT GST NUMBER" });
        if (res.success) {
          // console.log("ress", res.data);
          //in some GST numbers Address field is blank, checking address otherwise code will break bcoz of further array operations
          if (res?.data?.address) {
            let state = res?.data?.address?.split(",");
            setstate(state[state.length - 2].trim());

            let address = res?.data?.address?.split(",");
            let cityJurisdiction = res.data.center_jurisdiction
              .split(",")[0]
              .split(" ")[2];
            let city = selectCity(address, cityJurisdiction);
            setcity(city);
            // console.log("city final: ", city);
          } else {
            setstate("");
            setcity("");
          }

          setGSTSkip(true);
          setSkippage(2);
          setPage(1);

          // setGSTCurentData(res.data?.constitution_of_business);
          // setBusinessActivity(res.data?.taxpayer_type);
          setaddress(res?.data?.address);
          setbusinessName(res?.data?.business_name);
          setname(res?.data?.business_name);
          setGSTNumber(res?.data.gstin);
          setGstError("");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const SubmitGSTForm = async () => {
    if (!gstNumber || gstNumber == null) {
      setGstError({ message: "PLEASE ENTER CORRECT GST NUMBER" });
      return;
    }

    checkUniqueGST(gstNumber);
  };

  const SubmitBussinessActivityInfoFrom = async () => {
    let tocken = localStorage.getItem("@tocken");
    const AuthStr = "Bearer ".concat(tocken || "");
    seterrorMessages([]);
    await API.patch(
      `user/signup/checkbussinessactivityinfo`,
      {
        first_name: first_name,
        last_name: last_name,
        phone: phone,
        phonecode: phonecode,
        name: gstSkip ? name : businesname,
        address: address,
        city: gstSkip ? city : findArryCity,
        state: gstSkip ? state : findArressSate,
        other_branch: other_branch,
        typeofbussiness: gstData.includes("Select") ? "" : gstData,
        activity: bussinessActivity.includes("Select") ? "" : bussinessActivity,
        employees: empIndex,
        revenue: salaryIndex,
        gst: gstNumbertext,
      },
      {
        headers: { Authorization: AuthStr },
      }
    )
      .then((data) => {
        if (data.data.status) {
          setPage(4);
        } else {
          if (data.data.valData) {
            let dataerrer = data.data.valData.errors.map(
              (item: any, index: number) => {
                return { name: item.param, message: item.msg };
              }
            );
            seterrorMessages(dataerrer);
            errorData = errorData.push(dataerrer);
          } else {
            let item = { name: "all", message: data.data.message };
            seterrorMessages([item]);
          }
        }
      })
      .catch((error) => {});
  };
  // console.log("page", page);
  const SubmitBussinessInfoFrom = () => {
    let tocken = localStorage.getItem("@tocken");
    const AuthStr = "Bearer ".concat(tocken || "");
    seterrorMessages([]);
    API.patch(
      `user/signup/checkbussinessinfo`,
      {
        first_name: first_name,
        last_name: last_name,
        phone: phone,
        phonecode: phonecode,
        name: name,
        address: address,
        city: city,
        state: state,
        other_branch: other_branch.map((item: any, index: number) => {
          return item;
        }),
        gst: gstNumbertext,
        // typeofbussiness: TypeBussinessIndex,
        // activity: ActivityIndex,
        // employees: empIndex,
        // revenue: salaryIndex,
      },
      {
        headers: { Authorization: AuthStr },
      }
    )
      .then((data) => {
        if (data.data.status) {
          if (gstSkip) {
            setPage(3);
            setSkippage(3);
          } else {
            setPage(2);
          }
          // if (setSkippage === 3) {
          //   Props.history.push("/verify");
          // }
          // gstSkip ? setPage(3) : setPage(2);
        } else {
          if (data.data.valData) {
            let dataerrer = data.data.valData.errors.map(
              (item: any, index: number) => {
                return { name: item.param, message: item.msg };
              }
            );
            seterrorMessages(dataerrer);
          } else {
            let item = { name: "all", message: data.data.message };
            seterrorMessages([item]);
          }
        }
      })
      .catch((error) => {});
  };

  //API call for ADD COMPANY
  const submitAddCompanyForm = () => {
    let tocken = localStorage.getItem("@tocken");
    const AuthStr = "Bearer ".concat(tocken || "");
    seterrorMessages([]);

    API.post(
      `user/addCompany`,
      {
        email: email_id?.toLowerCase(),
        first_name: first_name,
        last_name: last_name,
        phone: phone,
        phonecode: phonecode,
        name: gstSkip ? name : businesname,
        address: address,
        city: gstSkip ? city : findArryCity,
        state: gstSkip ? state : findArressSate,
        other_branch: other_branch,
        typeofbussiness: gstData.includes("Select") ? "" : gstData,
        activity: bussinessActivity.includes("Select") ? "" : bussinessActivity,
        employees: empIndex,
        revenue: salaryIndex,
        gst: gstNumbertext ? gstNumbertext : undefined,
      },
      {
        headers: { Authorization: AuthStr },
      }
    )
      .then((data) => {
        // console.log(data);
        if (data.data.status) {
          setFinaSubmit(true);
          window.setTimeout(() => {
            //reset localStorage and redux store for these 2 variables(isCompanyAdded,AddedCompanyEmail) when form closed
            localStorage.removeItem("isCompanyAdded");
            localStorage.removeItem("AddedCompanyEmail");

            //reset global state for isAddCompany
            manualDispatch(setIsCompanyAdded(false));

            //reset global state for addCompanyEmailGlobal
            manualDispatch(setAddedCompanyEmail(""));

            //popup for company added
            swal(
              "Company Added!",
              "Company successfully added in your profile",
              "success"
            ).then(() => {
              window.setTimeout(() => {
                Props.history.goBack();
              }, 750);
            });
          }, 1000);
        } else {
          if (data.data.valData) {
            let dataerrer = data.data.valData.errors.map(
              (item: any, index: number) => {
                return { name: item.param, message: item.msg };
              }
            );
            seterrorMessages(dataerrer);
          } else {
            let item = { name: "all", message: data.data.message };
            seterrorMessages([item]);
          }
        }
      })
      .catch((error) => {
        const errorData = error.response.data;
        if (errorData) {
          if (errorData.valData) {
            let dataerrer = errorData.valData.errors.map(
              (item: any, index: number) => {
                return { name: item.param, message: item.msg };
              }
            );
            seterrorMessages(dataerrer);
          } else {
            let item = { name: "all", message: errorData.message };
            seterrorMessages([item]);
          }
        }
      });
  };

  const SubmitFrom = () => {
    let tocken = localStorage.getItem("@tocken");
    const AuthStr = "Bearer ".concat(tocken || "");
    seterrorMessages([]);
    // console.log('gstNumbertext: ',gstNumbertext);

    API.patch(
      `user/signup/updateinfo`,
      {
        first_name: first_name,
        last_name: last_name,
        phone: phone,
        phonecode: phonecode,
        name: gstSkip ? name : businesname,
        address: address,
        city: gstSkip ? city : findArryCity,
        state: gstSkip ? state : findArressSate,
        other_branch: other_branch,
        typeofbussiness: gstData.includes("Select") ? "" : gstData,
        activity: bussinessActivity.includes("Select") ? "" : bussinessActivity,
        employees: empIndex,
        revenue: salaryIndex,
        gst: gstNumbertext ? gstNumbertext : undefined,
      },
      {
        headers: { Authorization: AuthStr },
      }
    )
      .then((data) => {
        if (data.data.status) {
          setFinaSubmit(true);
          window.setTimeout(() => {
            Props.SetSignupName("");
            Props.SetSignUpPost(true);
            if (data.data.verified) {
              Props.history.push("/dashboard");
            } else {
              Props.history.push("/verify");
            }
          }, 1000);
        } else {
          if (data.data.valData) {
            let dataerrer = data.data.valData.errors.map(
              (item: any, index: number) => {
                return { name: item.param, message: item.msg };
              }
            );
            seterrorMessages(dataerrer);
          } else {
            let item = { name: "all", message: data.data.message };
            seterrorMessages([item]);
          }
        }
      })
      .catch((error) => {
        console.log("error");
        if (error.response) {
          // Request made and server responded
          console.log("data: ", error.response.data);
          if (error.response.data) {
            swal("Error!", error.response.data?.valData.errors[0].msg, "error");
          }
          if (error.response.data.isCompanyExists) {
            console.log("Error message: ", error.response.data.message);
            swal("Error!", error.response.data.message, "error");
          }
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
          // message.error("Error while adding new Admin user. Please try again!");
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }

        // if (error.data.isCompanyExists) {
        //   console.log("message: ", error.data.message);
        // }
      });
  };
  //upadte profile api
  const UpadteProfileApi = () => {
    let tocken = localStorage.getItem("@tocken");
    const AuthStr = "Bearer ".concat(tocken || "");
    seterrorMessages([]);
    API.patch(
      `/user/updateprofile`,
      {
        first_name: first_name,
        last_name: last_name,
        phone: phone,
        phonecode: phonecode,
      },
      {
        headers: { Authorization: AuthStr },
      }
    )
      .then((data) => {
        if (data.data.status) {
          setFinaSubmit(true);
          window.setTimeout(() => {
            Props.SetSignupName("");
            Props.SetSignUpPost(true);
            if (data.data.verified) {
              Props.history.push("/dashboard");
            } else {
              Props.history.push("/verify");
            }
          }, 1000);
        } else {
          if (data.data.valData) {
            let dataerrer = data.data.valData.errors.map(
              (item: any, index: number) => {
                return { name: item.param, message: item.msg };
              }
            );
            seterrorMessages(dataerrer);
          } else {
            let item = { name: "all", message: data.data.message };
            seterrorMessages([item]);
          }
        }
        //       console.log(
        // 'response data savead'
        //       )
      })
      .catch((error) => {
        if (error.response.data) {
          swal("Error!", error.response.data?.valData.errors[0].msg, "error");
        }
      });
  };

  // console.log('error Messages', errorMessages)
  // //Check if the fetched city is present in list and then only select
  //   let selectedCity = ""
  //   cityData.filter((x) => x.state == state)
  //   .forEach((item, index) => {
  //     if(item.name == city || city.includes(item.name )){
  //     selectedCity = item.name;
  //   }})
  //   console.log('selectCity',selectedCity)

  const handleGstChange = (e: any) => {
    //GST number is not allowed to enter after 15 digits
    if (e.target.value.length <= 15) {
      setGstData(e.target.value);
    }
    if (gstNumber.length > 0) {
      setGstError("");
    }
  };

  const handleChangeBussiness = (event: any) => {
    setGSTCurentData(event.target.value);
    //  TypeBussinessIndex(event.value)
  };
  const handleBusinessActivity = (event: any) => {
    setBusinessActivity(event.target.value);
  };
  const handleNumberEmp = (event: any) => {
    setEmpIndex(event.target.value);
  };
  const handleLastRevenue = (event: any) => {
    setSalaryIndex(event.target.value);
  };

  const handleChange = (event: any, newValue: any) => {
    setother_branch(newValue);
  };
  const handleChangeSingle = (event: any) => {
    setcity("");
    setstate(event.target.value);
  };
  const handleChangeSingleCity = (event: any) => {
    setcity(event.target.value);
  };
  const Skipform = () => {
    setGSTSkip(true);
    setSkippage(2);
    setaddress("");
  };
  const skipBackPage = (page: any) => {
    setSkippage(1);
    setPage(page);
  };

  const ShowError = (name: string) => {
    return errorMessages.filter((x) => x.name === name).length > 0;
  };
  const GetMessage = (name: string) => {
    return errorMessages.filter((x) => x.name === name)[0].message;
  };

  // useEffect(() => {
  //   if (!Props.isCompanyAdded) {
  //     let tocken = localStorage.getItem("@tocken");
  //     if (tocken) {
  //       const AuthStr = "Bearer ".concat(tocken ? tocken : "");
  //       // let id=localStorage.getItem(JSON.parse('company_id'))
  //       let id = Props.companyId;
  //       Api.get(`user/me`, {
  //         headers: { Authorization: AuthStr, companyId: id },
  //       }).then((data) => {
  //         if (data.data.status) {
  //           setemail(data.data.data?.email);
  //         }
  //       });
  //     }
  //   }
  // }, []);

  const handlePhoneNumber = (phoneNumber: any) => {
    let isNum = /^\d+$/.test(phoneNumber);
    // let isValidPhoneNumber = validator.isMobilePhone(phoneNumber);
    // console.log('ivpn',isNum)
    if ((isNum && phoneNumber.length <= 10) || phoneNumber === "") {
      setphone(phoneNumber);
    }
  };

  const handleMultiPart = () => {
    let dataError = [];
    if (!first_name) {
      dataError.push({ name: "first_name", message: "Pls enter First Name" });
    }
    if (!last_name) {
      dataError.push({ name: "last_name", message: "Pls enter Last Name" });
    }
    if (!phone) {
      dataError.push({ name: "phone", message: "Pls enter Phone Number" });
    }
    seterrorMessages(dataError);
    if (first_name && last_name && phone) {
      if (
        isInvited == "true" ||
        (isAssociatedToCompany && !isProfileCompleted)
      ) {
        UpadteProfileApi();
      } else {
        SubmitFrom();
      }
    }
  };
  // console.log('skipPage, page, gstSkip: ',skipPage, page, gstSkip);
  // console.log(city);
  // console.log(state);
  return (
    <div className="login-auth">
      {/* <SidebarPersonalInfo /> */}
      <div className="auth-Header">
        <div className="logo-icon">
          <img src={Logo} alt="mf logo" />
        </div>
      </div>

      <div
        className={
          (page === 1 && skipPage !== 2) || !gstSkip
            ? "gst-page user-page"
            : "right11 user-page"
        }
      >
        {Props.isCompanyAdded && (
          <CancelIcon
            color="error"
            fontSize="large"
            titleAccess="Close and Go Back"
            onClick={() => {
              //reset localStorage and redux store for these 2 variables(isCompanyAdded,AddedCompanyEmail) when form closed
              localStorage.removeItem("isCompanyAdded");
              localStorage.removeItem("AddedCompanyEmail");

              //reset global state for isAddCompany
              manualDispatch(setIsCompanyAdded(false));

              //reset global state for addCompanyEmailGlobal
              manualDispatch(setAddedCompanyEmail(""));

              //load and redirect to previous page
              window.setTimeout(() => {
                Props.history.goBack();
              }, 750);
            }}
            sx={{
              cursor: "pointer",
              backgroundColor: "white",
              borderRadius: "50%",
              position: "absolute",
              top: "75px",
              right: "15px",
              zIndex: 1,
            }}
          />
        )}
        {!(
          isInvited == "true" ||
          (isAssociatedToCompany && !isProfileCompleted)
        ) &&
          (!gstSkip && page !== 2 && page !== 3 ? (
            <div className="acount-detail">
              Provide your GST number and save time in writing your business
              details.
            </div>
          ) : (
            ""
          ))}

        <div className="right-section-wrp mr-auto">
          <>
            {" "}
            <img src={semicircle} alt="" className="semi-circle" />
            <img src={greencircle} alt="" className="green-cricle" />
            <img src={linecircle} alt="" className="line-shapes" />
            <img src={spark} alt="" className="forgetPassSparks center" />
          </>

          <div className="form-container1 right__form  bussines--panel">
            {page === 1 && (
              <form
                className="business_form"
                style={{
                  display: "flex",
                }}
              >
                {gstSkip && skipPage == 2 ? (
                  <>
                    <div className="login-text gstin">
                      <div className="login-main-text">Business Details.</div>
                    </div>
                    {gstNumbertext == 0 && (
                      <div className="login-sub-text gstin">
                        Provide these details if you do not know/have GST
                        number.
                      </div>
                    )}
                    {gstNumbertext != 0 && (
                      <div className="gst-in">GSTIN : {gstNumbertext}</div>
                    )}
                    <div className="setup-number">
                      <span className="setup-num-text active-page">
                        {gstNumbertext == 0 && (
                          <span className="number-text">1</span>
                        )}
                        {gstNumbertext != 0 && (
                          <CheckCircleIcon className="tick-color" />
                        )}
                        <span className="gst-name">GST Number</span>
                      </span>
                      <span className="setup-num-text">
                        <span className="number-text">2</span>
                        <span className="gst-name">Business Details</span>
                      </span>
                      <span className="setup-num-text">
                        <span className="number-text">3</span>
                        <span className="gst-name third-step">
                          Contact Details
                        </span>
                      </span>
                    </div>
                    <div className="form-control">
                      <div className="input__box11">
                        <TextField
                          id="outlined-basic"
                          value={name}
                          onChange={(e: any) => {
                            setname(e.target.value);
                          }}
                          label="Name of Business"
                          variant="outlined"
                          className="input-field"
                        />
                      </div>
                      {ShowError("name") ? (
                        <span className="tooltiptext">
                          {GetMessage("name")}
                        </span>
                      ) : null}
                    </div>
                    <div className="multi__form">
                      <div className="form-control">
                        <div className="input__box11">
                          <TextField
                            id="outlined-basic"
                            value={address}
                            onChange={(e: any) => {
                              setaddress(e.target.value);
                            }}
                            label="Business Address"
                            variant="outlined"
                            className="input-field"
                          />
                        </div>
                        {ShowError("address") ? (
                          <span className="tooltiptext">
                            {GetMessage("address")}
                          </span>
                        ) : null}
                      </div>
                      <div className="form-control">
                        <Box sx={{ minWidth: 300 }}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Select State
                            </InputLabel>

                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={state}
                              label="Select State"
                              onChange={handleChangeSingle}
                            >
                              {statedata.sort().map((item, index) => {
                                return state.includes(item) ? (
                                  <MenuItem value={state}>{state}</MenuItem>
                                ) : (
                                  <MenuItem value={item}>{item}</MenuItem>
                                );
                                // return <MenuItem value={item}>{item}</MenuItem>;
                              })}
                            </Select>
                          </FormControl>
                        </Box>
                        {ShowError("state") ? (
                          <span className="tooltiptext">
                            {GetMessage("state")}
                          </span>
                        ) : null}
                      </div>
                      <div className="form-control">
                        <Box sx={{ minWidth: 300 }}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Select City
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select1"
                              value={city}
                              // value={selectedCity}
                              // value={cityData.filter((x) => x.state == state)
                              //   .forEach((item, index) => {if(item.name == city || city.includes(item.name)){
                              //   selectCity = item.name;
                              //   return selectCity;
                              // }else})}
                              label="Select City"
                              placeholder="Select City"
                              onChange={handleChangeSingleCity}
                            >
                              {/* {city.length > 0 ? (
                                <MenuItem value={city}>{city}</MenuItem>
                              ) : } */}

                              {[
                                ...new Set(
                                  cityData.filter((x) => x.state == state)
                                ),
                              ].map((item, index) => {
                                return (
                                  <MenuItem
                                    key={`${item} ${index}`}
                                    value={item.name}
                                  >
                                    {item.name}
                                  </MenuItem>
                                );
                              })}
                              {/* {cityData.map((item, index) => {
                                //return { value: item, label: item };
                                return (
                                  <MenuItem value={item.name}>
                                    {item.name}
                                  </MenuItem>
                                );
                              })} */}
                            </Select>
                          </FormControl>
                        </Box>
                        {ShowError("city") ? (
                          <span className="tooltiptext">
                            {GetMessage("city")}
                            <br />
                          </span>
                        ) : null}
                      </div>
                      <div className="form-control">
                        <Autocomplete
                          className="multi-cehcked"
                          multiple
                          id="tags-outlined"
                          // options={cityData.map((item, index) => {
                          //   return item.name;
                          // })}
                          options={cityName}
                          defaultValue={other_branch}
                          value={other_branch}
                          getOptionLabel={(option: any) => option}
                          onChange={handleChange}
                          filterSelectedOptions
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              value={other_branch}
                              label="Other Branches"
                              placeholder="Other Branch"
                              className="auto-complte"
                            />
                          )}
                        />
                        {ShowError("other_branch") ? (
                          <span className="tooltiptext">
                            {GetMessage("other_branch")}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="login-text">
                      <div className="login-main-text">
                        {Props.isCompanyAdded ? "Add Company" : "Setup account"}
                      </div>
                    </div>
                    <div className="setup-number">
                      <span className="setup-num-text active-page">
                        {gstNumbertext == 0 && (
                          <span className="number-text">1</span>
                        )}
                        {gstNumbertext != 0 && (
                          <CheckCircleIcon className="tick-color" />
                        )}
                        {/* <CheckCircleIcon className="tick-color" /> */}
                        <span className="gst-name">GST Number</span>
                      </span>
                      <span className="setup-num-text">
                        <span className="number-text">2</span>
                        <span className="gst-name">Business Details</span>
                      </span>
                      <span className="setup-num-text">
                        <span className="number-text">3</span>
                        <span className="gst-name third-step">
                          Contact Details
                        </span>
                      </span>
                    </div>
                    <div className="form-control">
                      <div className="form__input-box">
                        <TextField
                          error={gstError?.message}
                          // error={false}
                          id="outlined-basic"
                          type="email"
                          value={gstNumber}
                          onChange={handleGstChange}
                          label="Enter GST Number"
                          variant="outlined"
                          className="input-field"
                        />
                      </div>
                      <label className="label-text gst-number">
                        Provide your GST number and save time in writing your
                        business details.
                      </label>
                      <span className="tooltiptext">{gstError?.message}</span>
                    </div>
                  </>
                )}

                <div className="btn-group">
                  {gstSkip ? (
                    ""
                  ) : (
                    <Button
                      className="skip-btn "
                      variant="contained"
                      onClick={() => {
                        Skipform();
                      }}
                    >
                      Skip
                    </Button>
                  )}

                  {gstSkip ? (
                    <>
                      <Button
                        className="skip-btn"
                        variant="contained"
                        onClick={() => {
                          setGSTSkip(false);
                          setGSTNumber("");
                        }}
                        startIcon={<ArrowBackIcon />}
                      >
                        Back
                      </Button>
                      <Button
                        variant="contained"
                        className="business_form-submit-btn"
                        onClick={() => {
                          SubmitBussinessInfoFrom();
                        }}
                        endIcon={<ArrowForwardIcon />}
                      >
                        Next
                      </Button>
                    </>
                  ) : (
                    <Button
                      variant="contained"
                      className="business_form-submit-btn"
                      onClick={() => {
                        SubmitGSTForm();
                      }}
                      endIcon={<ArrowForwardIcon />}
                    >
                      VERIFY
                    </Button>
                  )}
                </div>
              </form>
            )}
            {page === 3 && (
              <form
                className="business_form"
                style={{
                  display: "flex",
                }}
              >
                <div className="login-text gstin">
                  <div className="login-main-text">Business Details.</div>
                  {gstNumbertext == 0 && (
                    <div className="login-sub-text gstin">
                      Provide these details if you do not know/have GST number.
                    </div>
                  )}
                </div>
                {gstNumbertext != 0 && (
                  <div className="gst-in">GSTIN : {gstNumbertext}</div>
                )}
                <div className="setup-number">
                  <span className="setup-num-text active-page">
                    {gstNumbertext == 0 && (
                      <span className="number-text">1</span>
                    )}
                    {gstNumbertext != 0 && (
                      <CheckCircleIcon className="tick-color" />
                    )}
                    <span className="gst-name">GST Number</span>
                  </span>
                  <span className="setup-num-text">
                    <CheckCircleIcon className="tick-color" />
                    {/* <span className="number-text">2</span> */}
                    <span className="gst-name">Business Details</span>
                  </span>
                  <span className="setup-num-text">
                    <span className="number-text">3</span>
                    <span className="gst-name third-step">Contact Details</span>
                  </span>
                </div>

                <div className="form-control">
                  <Box sx={{ minWidth: 300 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Type of Business
                      </InputLabel>

                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={gstData}
                        label="Type of Business"
                        onChange={handleChangeBussiness}
                        // displayEmpty
                        // renderValue={gstData !== "" ? undefined : () => "placeholder text"}
                      >
                        {TypeBussiness.map((item: any, index) => {
                          return gstData.includes(item.title) ? (
                            <MenuItem value={gstData}>{gstData}</MenuItem>
                          ) : (
                            <MenuItem value={item?.title}>
                              {item?.title}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                  {ShowError("typeofbussiness") ? (
                    <span className="tooltiptext">
                      {GetMessage("typeofbussiness")}
                    </span>
                  ) : null}
                </div>

                <div className="form-control">
                  <Box sx={{ minWidth: 300 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Main business activity
                      </InputLabel>

                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={bussinessActivity}
                        label="Main business activity"
                        onChange={handleBusinessActivity}
                      >
                        {Activity.map((item: any, index) => {
                          return bussinessActivity.includes(item.title) ? (
                            <MenuItem value={bussinessActivity}>
                              {bussinessActivity}
                            </MenuItem>
                          ) : (
                            <MenuItem value={item?.title}>
                              {item?.title}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                  {ShowError("activity") ? (
                    <span className="tooltiptext">
                      {GetMessage("activity")}
                    </span>
                  ) : null}
                </div>
                <div className="form-control">
                  <Box sx={{ minWidth: 300 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Number of Employees
                      </InputLabel>

                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={empIndex}
                        label="Number of Employees"
                        onChange={handleNumberEmp}
                      >
                        {emp.map((item: any, index) => {
                          return (
                            <MenuItem value={item.title}>{item.title}</MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                  {ShowError("employees") ? (
                    <span className="tooltiptext">
                      {GetMessage("employees")}
                    </span>
                  ) : null}
                </div>
                <div className="form-control">
                  <Box sx={{ minWidth: 300 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Last year Revenue
                      </InputLabel>

                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={salaryIndex}
                        label="Number of Employees"
                        onChange={handleLastRevenue}
                      >
                        {salary.map((item: any, index) => {
                          // console.log(item, salaryIndex);
                          return salaryIndex.includes(item.title) ? (
                            <MenuItem value={salaryIndex}>
                              {salaryIndex}
                            </MenuItem>
                          ) : (
                            <MenuItem value={item?.title}>
                              {item?.title}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                  {ShowError("revenue") ? (
                    <span className="tooltiptext">{GetMessage("revenue")}</span>
                  ) : null}
                </div>
                <div className="btn-group">
                  <Button
                    className="skip-btn"
                    variant="contained"
                    onClick={() => {
                      setGSTSkip(true);
                      setSkippage(2);
                      setPage(1);
                    }}
                    startIcon={<ArrowBackIcon />}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    className="business_form-submit-btn"
                    onClick={() => {
                      SubmitBussinessActivityInfoFrom();
                    }}
                    endIcon={<ArrowForwardIcon />}
                  >
                    Next
                  </Button>
                </div>
              </form>
            )}

            {page === 4 && (
              <form
                className="business_form"
                style={{
                  display: "flex",
                }}
              >
                <div className="login-text">
                  <div className="login-main-text">Contact Details.</div>
                </div>
                {!(
                  isInvited == "true" ||
                  (isAssociatedToCompany && !isProfileCompleted)
                ) && (
                  <div className="setup-number">
                    <span className="setup-num-text active-page">
                      {gstNumbertext == 0 && (
                        <span className="number-text">1</span>
                      )}
                      {gstNumbertext != 0 && (
                        <CheckCircleIcon className="tick-color" />
                      )}
                      <span className="gst-name">GST Number</span>
                    </span>
                    <span className="setup-num-text">
                      <CheckCircleIcon className="tick-color" />
                      <span className="gst-name">Business Details</span>
                    </span>
                    <span className="setup-num-text">
                      {finalSubmit && (
                        <CheckCircleIcon className="tick-color" />
                      )}
                      {!finalSubmit && <span className="number-text">3</span>}
                      <span className="gst-name third-step">
                        Contact Details
                      </span>
                    </span>
                  </div>
                )}

                <div className="form-control">
                  <InputFIeld
                    placeholder="Please enter first name"
                    showError={false}
                    // disable={isAddedUserExist}
                    disable={false}
                    value={first_name}
                    onChange={(e: any) => {
                      setfirst_name(e.target.value);
                    }}
                  />
                  {ShowError("first_name") ? (
                    <span className="tooltiptext">
                      {GetMessage("first_name")}
                    </span>
                  ) : null}
                </div>

                <div className="form-control">
                  <InputFIeld
                    placeholder="Enter Last Name"
                    showError={false}
                    value={last_name}
                    // disable={isAddedUserExist}
                    disable={false}
                    onChange={(e: any) => {
                      setlast_name(e.target.value);
                    }}
                  />
                  {ShowError("last_name") ? (
                    <span className="tooltiptext">
                      {GetMessage("last_name")}
                    </span>
                  ) : null}
                </div>
                <div className="form-control">
                  <InputFIeld
                    placeholder="Enter Email id"
                    showError={false}
                    value={email_id}
                    disable={true}
                    onChange={(e: any) => {
                      setemail(e.target.value?.toLowerCase());
                    }}
                  />

                  {ShowError("email") ? (
                    <span className="tooltiptext">{GetMessage("email")}</span>
                  ) : null}
                </div>
                <div className="phone-input-boxss form-control">
                  <div className="phone-input-box-wrapddd">
                    <InputFIeld
                      placeholder="Phone Number"
                      showError={false}
                      value={phone}
                      // disable={isAddedUserExist}
                      disable={false}
                      onChange={(e: any) => {
                        handlePhoneNumber(e.target.value);
                      }}
                    />
                  </div>
                  {ShowError("phone") ? (
                    <span className="tooltiptext">{GetMessage("phone")}</span>
                  ) : null}
                  {ShowError("phonecode") ? (
                    <span className="tooltiptext">
                      {GetMessage("phonecode")}
                    </span>
                  ) : null}
                </div>

                <div className="btn-group">
                  {!(
                    isInvited == "true" ||
                    (isAssociatedToCompany && !isProfileCompleted)
                  ) &&
                    (gstSkip ? (
                      <Button
                        className="skip-btn"
                        variant="contained"
                        onClick={() => {
                          skipBackPage(3);
                          setFinaSubmit(false);
                        }}
                        startIcon={<ArrowBackIcon />}
                      >
                        Back
                      </Button>
                    ) : (
                      <Button
                        className="skip-btn"
                        variant="contained"
                        onClick={() => {
                          setPage(2);
                          setFinaSubmit(false);
                        }}
                        startIcon={<ArrowBackIcon />}
                      >
                        Back
                      </Button>
                    ))}
                  {Props.isCompanyAdded ? (
                    <Button
                      variant="contained"
                      className="business_form-submit-btn"
                      onClick={() => {
                        submitAddCompanyForm();
                      }}
                      endIcon={<ArrowForwardIcon />}
                    >
                      DONE
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      className="business_form-submit-btn"
                      onClick={() => {
                        handleMultiPart();
                      }}
                      endIcon={<ArrowForwardIcon />}
                    >
                      Complete Sign up
                    </Button>
                  )}
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state: any) => {
  return {
    signup_name: state.user.signup_name,
    post_sign_up: state.user.post_sign_up,
    companyId: state.user.companyId,
    isCompanyAdded: state.user.isCompanyAdded,
    addedCompanyEmail: state.user.addedCompanyEmail,
  };
};
const mapDispatchToProps = (dispatch: any) => ({
  SetSignupName: (name: string) => dispatch(SetSignupName(name)),
  SetSignUpPost: (val: boolean) => dispatch(SetSignUpPost(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfoForm);
