import "../styles/authForm.css";
import SidebarInfo from "./SidebarInfo";
import checked from "../images/checked.png";
import API from "../Helper/Api";
import swal from "sweetalert";
import Logo from "../images/MF.png";
interface Props {
  text1: string;
  text2: string;
  isResend: boolean;
  resendText: string;
  onpress: any;
  post_sign_up: boolean;
}
const VerifyPage = (Props: Props) => {
  // console.log("vp", Props);
  const SubmitFrom = () => {
    let tocken = localStorage.getItem("@tocken");
    const AuthStr = "Bearer ".concat(tocken || "");
    API.patch(
      `user/account/resend`,
      {},
      {
        headers: { Authorization: AuthStr },
      }
    )
      .then((data) => {
        if (data.data.status) {
          swal("Success", data.data.message, "success");
        } else {
        }
      })
      .catch((error) => {});
  };
  return (
    <>
      <div className="auth-Header">
        <div className="logo-icon">
          <img src={Logo} alt="mf logo" />
        </div>
      </div>
      <div className="auth-container verfiy-email">
        {/* <SidebarInfo /> */}
        <div className="right">
          <div
            onClick={() => {
              Props.onpress();
            }}
            className="verify-page"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src={checked}
              alt="check icon"
              style={{
                width: "60px",
                height: "60px",
                marginBottom: "30px",
              }}
            />
            <p
              className="email_verify-note"
              style={{
                fontWeight: 500,
              }}
            >
              {Props.text1}
            </p>
            <p
              className="resend"
              style={{
                fontSize: "15px",
                fontWeight: 600,
                marginTop: "20px",
              }}
            >
              {Props.text2}{" "}
              {Props.isResend && (
                <span
                  onClick={() => {
                    SubmitFrom();
                  }}
                  style={{
                    fontSize: "15px",
                    fontWeight: 600,
                    color: "#52C5D4",
                    cursor: "pointer",
                  }}
                >
                  {Props.resendText}
                </span>
              )}
            </p>
          </div>
          {/* <p className="footer-text">©️ Cuentas Technologies Pvt Ltd</p> */}
        </div>
      </div>
    </>
  );
};
VerifyPage.defaultProps = { post_sign_up: false };
export default VerifyPage;
