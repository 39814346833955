import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper, { PaperProps } from "@mui/material/Paper";
import { Divider } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
// import Draggable from 'react-draggable';

const Popup = (props: any) => {
  return (
    <>
      <Dialog
        open={props.open}
        //pop up close on pressing 'escape' key
        onClose={(event: {}, reason: "backdropClick" | "escapeKeyDown") => {
          if (reason === "escapeKeyDown") {
            props.handleCancelClick();
          }
        }}
        maxWidth="lg"
        fullWidth
        aria-labelledby="draggable-dialog-title"
        className="custom-popup"
      >
        <DialogContent>{props.content}</DialogContent>
        <Divider />
        <DialogActions className="group-btn">
          <Button
            autoFocus
            onClick={props.handleCancelClick}
            className="cancel-btn"
          >
            Cancel
          </Button>
          <Button
            autoFocus
            onClick={props.onSave}
            className={`save-btn ${props.isLoading ? "disable-action" : ""}`}
          >
            {props.isLoading ? (
              <div>
                <Box sx={{ display: "flex" }}>
                  <span style={{ fontSize: "12px" }}>uploading </span>
                  <CircularProgress
                    style={{
                      marginLeft: "8px",
                      height: "18px",
                      width: "18px",
                      color: "#fff",
                    }}
                  />
                </Box>
              </div>
            ) : (
              "Save"
            )}
          </Button>
          {/* <Button onClick={handleClose}>Subscribe</Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Popup;
