import '../styles/home.css';
import Logo from '../images/MF.png';
import API from '../Helper/Api';
import { useEffect, useState } from 'react';
interface Props {
	history: any;
}
const Privacy = (Props: Props) => {
	const [ content, setcontent ] = useState('');
	useEffect(() => {
		LoadData();
		return () => {};
	}, []);
	const LoadData = () => {
		API.get(`setting/get/setting/data`)
			.then((data) => {
				// console.log(data.data);
				setcontent(data.data.data.privacy);
			})
			.catch((error) => {});
	};
	return (
		<div className="container">
			<div className="navbar">
				<img
					src={Logo}
					alt="mf logo"
					style={{
						width: '130px',
						height: '50px',
						objectFit: 'cover'
					}}
				/>
			</div>

			<div className="hero">
				<div className="hero__full">
					<div className="left__head">
						<p>
							<span className="left__head-1">Privacy Policy</span>
						</p>
					</div>
					<div className="left__title">
						<p
							className="title1"
							dangerouslySetInnerHTML={{
								__html: content
							}}
						/>
					</div>
				</div>
				<div className="hero__right" />
				{/* <div className="float"></div> */}
			</div>
		</div>
	);
};

export default Privacy;
