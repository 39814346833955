import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

const config = {
	apiKey: 'AIzaSyCkeuEkruBDoB1oN8-KPBZgz-63UHHEcSk',
	authDomain: 'fingurus-2ac9f.firebaseapp.com',
	projectId: 'fingurus-2ac9f',
	storageBucket: 'fingurus-2ac9f.appspot.com',
	messagingSenderId: '579686849730',
	appId: '1:579686849730:web:50a1a0c1a426c109a4af5b',
	measurementId: 'G-0T6EHX948H'
};

const app = initializeApp(config);

export const auth = getAuth();

const provider = new GoogleAuthProvider();
provider.setCustomParameters({ prompt: 'select_account' });
export const signInWithGoogle = () => signInWithPopup(auth, provider);

export default app;
