import axios from "axios";
let url = "";
let statucurl = "";
// console.log("NODE_ENV", process.env);

if (process.env.REACT_APP_ENV === "production") {
  url = "https://app.magikfin.com/api/";
  statucurl = "https://app.magikfin.com/api";
} else if (process.env.REACT_APP_ENV === "staging") {
  url = "http://staging.magikfin.com/api/";
  statucurl = "http://staging.magikfin.com/api";
} else {
  // url = "http://localhost:6800/";
  // statucurl = "http://localhost:6800";
  url = "http://staging.magikfin.com/api/";
  statucurl = "http://staging.magikfin.com/api";
}
export const path = () => {
  return statucurl;
};
export default axios.create({
  baseURL: url,
});
