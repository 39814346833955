import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Navbar from "../components/Navbar";
import BusinessInfoUpdate from "./BusinessInfoUpdate";
import PersonalUpdate from "./PersonalUpdate";
import API from "../Helper/Api";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
} from "@mui/material";
import Select from "@mui/material/Select";
import { useDispatch } from "react-redux";
import { ReactComponent as Person } from "../images/svg/person-lines.svg";
import { ReactComponent as Folder } from "../images/svg/folder3.svg";
import Team from "./Team";
import { SetCompId, FetchLogin } from "../reducers/user/actionCreator";
interface Props {
  location: any;
  history: any;
  first_name: string;
  last_name: string;
  user_data: any;
  companyId: string;
  bussness_name: string;
}
const ProfileAndBusinessUpdate = (Props: Props) => {
  // console.log('Props: ',Props)
  const [index, setIndex] = useState(0);
  const [companyName, setCompany] = useState(
    localStorage.getItem("company_id")
  );

  const manualDispatch = useDispatch();

  const selectCompany = (evt: any) => {
    const companyId = evt.target.value;
    setCompany(companyId);
    manualDispatch(FetchLogin(companyId));
    manualDispatch(SetCompId(companyId));
  };
  const findValByKey = (arr: any[], key: string) => {
    let findKey = key ? key : arr;
    const value = arr?.filter((itemInArray) => itemInArray?.id == key);
    return value?.length > 0 ? value[0]?.id : "";
  };
  return (
    <div
      className="update-container"
      style={{
        height: "100%",
        // overflow: 'hidden'
      }}
    >
      <Navbar history={Props.history} />
      <div className="main">
        {/* <div className="main__head">
          <p className="main__head-profile">Profile</p>
          <p className="main__head-name">
            Hello {Props.first_name} {Props.last_name}
          </p>
          <p className="main__head-mark">{Props.bussness_name}</p>
        </div> */}
        <div className="date-main__head dashboard-header">
          <div className="dashboard-header-left">
            {/* <p className="main__head-profile">DashBoard</p> */}
            <p className="main__head-name">
              Hi {Props.first_name?.toLowerCase()}{" "}
              {Props.last_name?.toLowerCase()}
              {/* {Props.last_name} ! */}
            </p>
          </div>
          <div className="dashboard-header-right">
            <Box sx={{ minWidth: 300 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select the name of company
                </InputLabel>

                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={companyName}
                  label="Select the name of company"
                  onChange={selectCompany}
                >
                  {Props?.user_data?.data?.companies?.map((company: any) => (
                    <MenuItem value={company.id}>{company.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </div>
        </div>
        <div className="company-name">{Props.bussness_name}</div>

        <div className="main__tab-container">
          <div className="main__tab">
            <div className="account-seeting">Account Settings</div>
            <div style={{ marginRight: "auto", marginLeft: 0 }}>
              <div
                className={`  account-tabing ${index === 0 && "tabactive"}`}
                onClick={() => setIndex(0)}
              >
                <Person />
                &nbsp;
                <div className="tab-hedding">Contact Information</div>
              </div>
              <div
                className={`daytab account-tabing ${
                  index === 1 && "tabactive"
                }`}
                onClick={() => setIndex(1)}
              >
                <Folder />
                &nbsp;
                <div className="tab-hedding">Business Info</div>
              </div>
              <div
                className={`daytab account-tabing ${
                  index === 2 && "tabactive"
                }`}
                onClick={() => setIndex(2)}
              >
                <Folder />
                &nbsp;
                <div
                  style={{ width: "98px", paddingRight: "20px" }}
                  className="tab-hedding"
                >
                  Team Info{" "}
                </div>
              </div>
            </div>
          </div>
          {index === 0 ? (
            <PersonalUpdate />
          ) : index === 1 ? (
            <BusinessInfoUpdate />
          ) : (
            <Team />
          )}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  first_name: state.user.first_name,
  last_name: state.user.last_name,
  bussness_name: state.user.bussness_name,
  user_data: state.user.user_data,
  companyId: state.user.companyId,
});
const mapDispatchToProps = (dispatch: any) => ({
  // startAction: () => dispatch(startAction),
  // stopAction: () => dispatch(stopAction)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileAndBusinessUpdate);
