import React from 'react';
import VerifyPage from '../components/VerifyPage';
interface Props {
	history: any;
}
const EmailVerify = (Props: Props) => {
	return (
		<VerifyPage
			text1="We have sent you an email activation link to verify your account."
			text2="Not received activation link yet?"
			isResend={true}
			resendText="Resend link"
			onpress={() => {
				// Props.history.push('thankyou');
			}}
		/>
	);
};

export default EmailVerify;
