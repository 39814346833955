import { Close } from "@mui/icons-material";
import { Box, IconButton, Popover, Popper } from "@mui/material";

function ComplianceInfoPopper({ anchorEl, complianceInfo, closePopper }) {
  const open = Boolean(anchorEl);

  return (
    <>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={closePopper}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          sx: {
            width: 500,
            mt: 2,
            ml: 6,
            boxShadow: "0px 8px 12px 2px rgba(16, 24, 40, 0.15)",
            filter: "drop-shadow(0px 4px 4px rgba(16, 24, 40, 0.2))",
            borderRadius: "6px",
            border: 1,
            borderColor: "#D0D5DD",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: 1,
            borderColor: "#D0D5DD",
            borderRadius: "6px 6px 0 0",
            pl: 1,
            bgcolor: "#f5f0ff",
            fontWeight: "bold",
            color: "#1D2939",
            textTransform: "capitalize",
          }}
        >
          {complianceInfo.title}
          <IconButton onClick={closePopper}>
            <Close color="primary" />
          </IconButton>
        </Box>
        <Box
          sx={{
            p: 1,
            height: 200,
            overflow: "auto",
            fontSize: "14px",
          }}
        >
          {`${complianceInfo.content}`}
        </Box>
      </Popover>
    </>
  );
}

export default ComplianceInfoPopper;
