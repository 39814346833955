import React, { useEffect, useState } from "react";
import "../styles/newPassword.css";
import Logo from "../images/MF.png";
import {
  MdExitToApp,
  MdHelp,
  MdLockOpen,
  MdDeveloperBoard,
  MdPerson,
  MdArrowDropDown,
  MdArrowDropUp,
  MdAccountCircle,
  MdAddBusiness,
} from "react-icons/md";
import { connect } from "react-redux";
import {
  FetchLogin,
  deleteDetails,
  setAddedCompanyEmail,
  setIsCompanyAdded,
} from "../reducers/user/actionCreator";
import { Logout } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";

import { path } from "../Helper/Api";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

interface Props {
  first_name: string;
  last_name: string;
  bussness_name: string;
  FetchLogin: any;
  history: any;
}
const Navbar = (Props: Props) => {
  const [showPopup, setShowPopup] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [addCompanyEmail, setAddCompanyEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const manualDispatch = useDispatch();

  useEffect(() => {
    //reset Error message
    setErrorMessage("");

    //reset global state for isAddCompany
    manualDispatch(setIsCompanyAdded(false));

    //reset global state for addCompanyEmail
    manualDispatch(setAddedCompanyEmail(""));

    //reset localStorage for these 2 variables(isCompanyAdded,AddedCompanyEmail) when nav drop down open
    localStorage.removeItem("isCompanyAdded");
    localStorage.removeItem("AddedCompanyEmail");

    if (localStorage.hasOwnProperty("@tocken")) {
    } else {
      window.location.href = "/login";
    }
    let id = localStorage.getItem("company_id");

    Props.FetchLogin(id);
    return () => {};
  }, []);

  const handleDismiss = (e: any) => {
    setShowPopup(false);
  };
  const Reset = () => {
    localStorage.removeItem("@tocken");
    localStorage.removeItem("company_id");
    manualDispatch(deleteDetails());

    Props.history.push("/login");
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="home-navbar">
      <img
        onClick={() => {
          Props.history.push("/dashboard");
        }}
        src={Logo}
        alt="mf logo"
        style={{
          cursor: "pointer",
          height: 50,
          objectFit: "cover",
        }}
      />
      <div className="navbar__menu">
        <div
          className="navbar__profile"
          onClick={() => {
            setShowPopup(!showPopup);
          }}
        >
          {/* {Props.first_name
            ? Props.first_name.substr(0, 1).toLocaleUpperCase()
            : "A"} */}
          <MdAccountCircle size={25} />

          <div
            onClick={(e) => handleDismiss(e)}
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              left: 0,
              top: 0,
              zIndex: 100,
              display: showPopup ? "block" : "none",
              cursor: "auto",
            }}
          >
            <div
              className="navbar__popup-menu"
              style={{ display: showPopup ? "block" : "none" }}
            >
              <ul>
                <li
                  onClick={() => {
                    Props.history.push("/dashboard");
                  }}
                >
                  <MdDeveloperBoard
                    style={{
                      marginRight: "10px",
                    }}
                  />{" "}
                  Dashboard
                </li>
                <li
                  onClick={() => {
                    handleClickOpen();
                  }}
                >
                  <MdAddBusiness
                    style={{
                      marginRight: "10px",
                    }}
                  />{" "}
                  Add Company
                </li>
                <li
                  onClick={() => {
                    Props.history.push("/profile");
                  }}
                >
                  <MdPerson
                    style={{
                      marginRight: "10px",
                    }}
                  />{" "}
                  Profile
                </li>
                <li
                  onClick={() => {
                    Props.history.push("/support");
                  }}
                >
                  <MdHelp
                    style={{
                      marginRight: "10px",
                    }}
                  />{" "}
                  Support
                </li>
                <li onClick={() => Reset()}>
                  <MdExitToApp
                    style={{
                      marginRight: "10px",
                    }}
                  />{" "}
                  <span>Logout</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* ADD-COMPANY POP UP */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Company</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter email address of the company you want to add:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
            variant="outlined"
            onChange={(event) => {
              setErrorMessage("");
              setAddCompanyEmail(event.target.value?.toLowerCase());
            }}
          />
          <span className="tooltiptext">{errorMessage}</span>
        </DialogContent>
        <DialogActions>
          <Button
            className="cancel-btn"
            variant="outlined"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className="save-btn"
            endIcon={<ArrowForwardIcon />}
            onClick={() => {
              if (!addCompanyEmail || !addCompanyEmail.includes("@")) {
                setErrorMessage("Please enter valid Email id");
              } else {
                //set global state for isAddCompany = true
                manualDispatch(setIsCompanyAdded(true));
                localStorage.setItem("isCompanyAdded", "true");

                //set global state for addCompanyEmail
                manualDispatch(setAddedCompanyEmail(addCompanyEmail));
                localStorage.setItem("AddedCompanyEmail", addCompanyEmail);

                //loading...
                window.setTimeout(() => {
                  Props.history.push("/addcompany");
                  // Props.history.push("/info");
                }, 1000);

                // handleClose();
              }
            }}
          >
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  first_name: state.user.first_name,
  last_name: state.user.last_name,
  bussness_name: state.user.bussness_name,
  companyId: state.user.companyId,
});
const mapDispatchToProps = (dispatch: any) => ({
  FetchLogin: () => dispatch(FetchLogin()),
  // stopAction: () => dispatch(stopAction)
});

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
