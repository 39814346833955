import React, { useEffect, useState } from "react";

import API from "../Helper/Api";
import VerifyPage from "../components/VerifyPage";
interface Props {
  history: any;
  match: any;
}
const EmailAction = (Props: Props) => {
  const [message, setmessage] = useState("Processing");
  const [message1, setmessage1] = useState("");
  useEffect(() => {
    if (Props.match.params.param && Props.match.params.param.length > 0) {
      SubmitFrom(Props.match.params.param);
    }
    return () => {};
  }, []);
  const SubmitFrom = (codestr: string) => {
    API.get(`complince/emailAction/${codestr}`)
      .then((data) => {
        if (data.data.status) {
          setmessage(`${data.data.message}`);
          setmessage1("");
          localStorage.setItem("@tocken", data.data.data.token);
        } else {
          setmessage(`${data.data.message}`);
          setmessage1("Your verification code expired");
        }
      })
      .catch((error) => {
        console.log("Email Action error:", error.response);
        setmessage(`${error.response.data.message}`);
        setmessage1("Something went wrong");
      });
  };
  return (
    <VerifyPage
      text1={message}
      text2={message1}
      resendText=""
      isResend={false}
      onpress={() => {}}
    />
  );
};

export default EmailAction;
