import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useState } from "react";

function ComplianceEditorDialog({ open, onClose, onSubmit, loading }) {
  const initialFormData = {
    date: "",
    complianceType: "",
    complianceDetails: "",
    penalty: "",
  };
  const [formData, setFormData] = useState(initialFormData);

  const handleFormFieldChange = ({ target: { name, value } }) => {
    if (name === "date") {
      setFormData((formData) => ({
        ...formData,
        date: value + "T00:00:00.000Z",
      }));
    } else {
      setFormData((formData) => ({ ...formData, [name]: value }));
    }
  };

  const handleClose = () => {
    setFormData(initialFormData);
    onClose();
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={(event) => onSubmit(event, formData, handleClose)}>
          <DialogTitle>Create Compliance</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Add any custom compliance to the calendar
            </DialogContentText>

            <TextField
              margin="dense"
              label="Due Date"
              fullWidth
              variant="outlined"
              required
              name="date"
              type="date"
              onChange={handleFormFieldChange}
              value={formData.date.split("T")[0]}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              margin="dense"
              label="Compliance Type"
              fullWidth
              variant="outlined"
              required
              name="complianceType"
              onChange={handleFormFieldChange}
              value={formData.complianceType}
            />
            <TextField
              margin="dense"
              label="Compliance Detail"
              fullWidth
              variant="outlined"
              name="complianceDetails"
              onChange={handleFormFieldChange}
              value={formData.complianceDetails}
            />
            <TextField
              margin="dense"
              label="Penalty"
              fullWidth
              variant="outlined"
              name="penalty"
              onChange={handleFormFieldChange}
              value={formData.penalty}
            />
          </DialogContent>
          <DialogActions>
            <Button disabled={loading} variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="contained" type="submit">
              {loading ? (
                <div>
                  <Box sx={{ display: "flex" }}>
                    <span style={{ fontSize: "12px" }}>Creating </span>
                    <CircularProgress
                      style={{
                        marginLeft: "8px",
                        height: "18px",
                        width: "18px",
                        color: "#fff",
                      }}
                    />
                  </Box>
                </div>
              ) : (
                "Create"
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export default ComplianceEditorDialog;
