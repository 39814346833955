// import moment from "moment";
import "../../styles/home.css";
import "../../styles/newPassword.css";
import Navbar from "../../components/Navbar";
// import MonthTab from "../components/MonthTab";
// import DayTab from "../components/DayTab";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import { connect } from "react-redux";
import {
  SetCompId,
  FetchLogin,
  SetFinYear,
  fileActions,
  downloadActions,
} from "../../reducers/user/actionCreator";
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";
import CircleTwoToneIcon from "@mui/icons-material/CircleTwoTone";

import { useDispatch } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Badge,
  Box,
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { ReactComponent as Receipt } from "../../images/svg/receipt.svg";
import { ReactComponent as Clipboard } from "../../images/svg/clipboard-x.svg";
import { ReactComponent as Checkcircle } from "../../images/svg/check-circle.svg";
import { ReactComponent as JournalArrow } from "../../images/svg/journal-arrow-down.svg";
import { ReactComponent as Bullseye } from "../../images/svg/bullseye2.svg";
import Custompopup from "../../components/Custopm-popup";
import { useEffect, useState, useRef, Fragment } from "react";
import Api from "../../Helper/Api";
import moment from "moment";
import { AnyNsRecord } from "dns";
import { path } from "../../Helper/Api";
import { Add, AddCircle, Info } from "@mui/icons-material";
import { ToastContainer, toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import ComplianceInfoPopper from "./components/ComplianceInfoPopper";
import ComplianceEditorDialog from "./components/ComplianceEditorDialog";
// import { saveAs } from 'file-saver';

interface Props {
  location: any;
  history: any;
  first_name: string;
  last_name: string;
  bussness_name: string;
  user_data: any;
  companyId: string;
  financialYearGlobal: number;
  file_action: any;
  downloading: any;
}

interface RefObject<T> {
  readonly current: T | null;
}

const TextButton = styled(Button)(({ theme, color = "primary" }) => ({
  ":hover": {
    backgroundColor: "rgb(103 58 183 / 10%)",
  },
}));

const Home = (Props: Props) => {
  const fileSaver = require("file-saver");

  const [dashboardData, setDashboardData] = useState<any>({});
  const ref = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [fileNames, setFileNames] = useState<any>([]);
  const [selectedFile, setSelectedFile] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [saveToDB, setSaveToDB] = useState<any>([]);
  const [complinceList, setComplinceList] = useState<any>([]);
  const [unsaveComplinceList, setUnsaveComplinceList] = useState<any>([]);
  const [unsavedDeletedFiles, setUnsavedDeletedFiles] = useState<any>([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [complianceInfo, setComplianceInfo] = useState({
    title: "",
    content: "",
  });
  const [openComplianceForm, setOpenComplianceForm] = useState(false);
  const [loading, setLoading] = useState({ createCompliance: false });

  const months = [
    {
      m: "Apr",
      full: "april",
      date: [],
    },
    {
      m: "May",
      date: [],
      full: "may",
    },
    {
      m: "Jun",
      date: [],
      full: "june",
    },
    {
      m: "Jul",
      date: [],
      full: "july",
    },
    {
      m: "Aug",
      date: [],
      full: "august",
    },
    {
      m: "Sep",
      date: [],
      full: "september",
    },
    {
      m: "Oct",
      date: [],
      full: "october",
    },
    {
      m: "Nov",
      date: [],
      full: "november",
    },
    {
      m: "Dec",
      date: [],
      full: "december",
    },
    {
      m: "Jan",
      full: "january",
      date: [],
    },
    {
      m: "Feb",
      full: "february",
      date: [],
    },
    {
      m: "Mar",
      full: "march",
      date: [],
    },
  ];
  const manualDispatch = useDispatch();
  // manualDispatch(fileActions(false))

  //FY data for drop down
  const fYearData: number[] = [];
  //Get current financial year
  const todayDate = new Date();
  let currentFY = todayDate.getUTCFullYear();
  let month = todayDate.getUTCMonth();
  if (month >= 0 && month <= 2) {
    currentFY--;
  }

  //Push the year in array in descending order
  let year = currentFY;
  while (year >= 2021) {
    fYearData.push(year--);
  }

  const [financialYear, setFinancialYear] = useState(() =>
    Props.financialYearGlobal
      ? localStorage.getItem("fyear") ?? Props.financialYearGlobal
      : currentFY
  );
  const [companyName, setCompany] = useState(() =>
    localStorage.getItem("company_id")
  );
  const [calendarList, setCalendarList] = useState<any>([]);

  useEffect(() => {
    if (companyName !== "") {
      manualDispatch(fileActions(false));

      getUserData(null);
      getSummaryData();
    }
  }, [companyName]);

  const [isOpen, setIsOpen] = useState(false);
  const [clanderInfo, setclanderInfo] = useState<any>([]);
  //open dialog box to delete attachment
  const [open, setOpen] = useState(false);
  const [deletingAttachment, setDeletingAttachment] = useState("");
  const [attachmentIndex, setAttachmentIndex] = useState("");

  //open dialog box when Cancel button clicked
  const [cancelOpen, setCancelOpen] = useState(false);
  const [isInfoUpdated, setIsInfoUpdated] = useState(false);

  useEffect(() => {
    manualDispatch(SetFinYear(financialYear));
  }, [financialYear]);

  useEffect(() => {
    getUserData(null);
    getSummaryData();
  }, [Props.financialYearGlobal]);

  useEffect(() => {
    manualDispatch(fileActions(false));
  }, []);

  const infoData = (clanderpopupData: any) => {
    let _dRange = clanderpopupData.isoDate.split("T");
    let dateObj = {
      end_date: _dRange[0] + "T23:59:00.000Z",
      start_date: _dRange[0] + "T00:00:00.000Z",
      companyId: Props.companyId,
    };
    getComplianceDetails(Props.companyId, clanderpopupData.id);
  };

  const getComplianceDetails = (companyId: any, complianceId: any) => {
    let token = localStorage.getItem("@tocken");
    const AuthStr = "Bearer ".concat(token || "");
    const headers = {
      "Content-Type": "application/json",
      Authorization: AuthStr,
    };
    Api.post(
      "complince/getComplianceDetails",
      { companyId, complianceId },
      { headers }
    ).then((res) => {
      res.data.data.forEach((e: any, i: number) => {
        e.status = ["Complete", "Pending"];
        e.dueDate = moment.utc(e.date).format("DD-MM-YYYY");
      });

      let requiredResult;

      setclanderInfo(res.data.data);

      requiredResult = res?.data?.data.map((item: any, index: any) => {
        if (item.actions.length > 0) {
          return item.actions[0];
        } else {
          return {
            complince_id: item.id,
            docList: [],
          };
        }
      });
      let files = requiredResult.map((item: any, id: any) => {
        let complianceId = item.complince_id;
        return item.docList.map((data: any) => {
          data.complianceId = complianceId;

          return data;
        });
      });
      var merged = [].concat.apply([], files);

      setFileNames(merged);
      setIsInfoUpdated(false);
      setIsOpen(true);
      setComplinceList([...requiredResult]);
      //deep copy of 'setUnsaveComplinceList' - array of objects to prevent mutation
      setUnsaveComplinceList(JSON.parse(JSON.stringify(requiredResult)));
      setUnsavedDeletedFiles([]);
      return;
    });
  };

  const deleteFileInCompliance = (item: any, index: any) => {
    let data = [...complinceList];
    if (data.length > 0) {
      for (let i = 0; i < data?.length; i++) {
        if (data[i].complince_id == index) {
          let doc = data[i]?.docList;
          for (let j = 0; j < doc?.length; j++) {
            if (item.fileName == doc[j]?.fileName) {
              doc?.splice(j, 1);
              break;
            }
          }
        } //rkwelkrjweklr
      }
      setComplinceList(data);
    }
    return;
  };

  const handleCancelClose = () => {
    manualDispatch(fileActions(false));

    setCancelOpen(false);
  };

  const deleteFromS3 = async (deleteFiles: any) => {
    let tocken = localStorage.getItem("@tocken");
    //in some files 'path' key is present and in other it is 's3Path'
    let files = deleteFiles.map((files: any, id: any) =>
      files.path ? files.path : files.s3Path
    );
    const AuthStr = "Bearer ".concat(tocken || "");
    const Headers = {
      "Content-Type": "application/json",
      Authorization: AuthStr,
    };
    let data = {
      filePathList: files,
    };
    if (files.length > 0) {
      try {
        const response = await fetch(`${path()}/complince/deleteDocuments`, {
          method: "POST",
          headers: Headers,
          body: JSON.stringify(data),
        });
        const res = await response.json();
        if (res.status) {
          //cancel Pop-up closed
          setCancelOpen(false);
          //compliance pop-up closed
          setIsOpen(false);

          return;
        } else {
          console.log(res.status);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const deleteFilesOnCancel = async () => {
    //Filtered Out (s3Path of files from docList) from saved files list
    let savedFilesFilter = [...unsaveComplinceList].map((complince: any) => {
      return complince.docList.map((doc: any) => doc.s3Path);
    });

    //flatten the nested array
    let savedFilesS3Path: any = [].concat.apply([], savedFilesFilter);

    //filtered out files not present earlier in saved files OR delete only unsaved files
    let deleteFilesOnCancel = fileNames.filter((file: any) => {
      return !savedFilesS3Path.includes(file.path ? file.path : file.s3Path);
    });

    unsavedDeletedFiles.forEach((file: any) => {
      if (!savedFilesS3Path.includes(file.path ? file.path : file.s3Path)) {
        deleteFilesOnCancel.push(file);
      }
    });

    if (deleteFilesOnCancel) {
      deleteFromS3(deleteFilesOnCancel);
    }
    //cancel Pop-up closed
    setCancelOpen(false);
    //compliance pop-up closed
    setIsOpen(false);
  };

  //for delete attachment dialog box open/close
  const handleOpen = (attachmentFile: any, index: any) => {
    setOpen(true);
    setDeletingAttachment(attachmentFile);
    setAttachmentIndex(index);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteClick = async (item: any, index: any) => {
    let filesIndex = fileNames.filter((files: any, id: any) =>
      files.complianceId == complinceList[index].complince_id
        ? complinceList[index].complince_id
        : complinceList[index].complianceId
    );
    let result = filesIndex.filter((file: any) =>
      file?.name ? file.name : file.fileName == item.fileName
    );

    let finalResult = fileNames.filter(
      (file: any) => file.fileName != result[0].fileName
    );

    setUnsavedDeletedFiles((prevState: any) => {
      prevState.push(result[0]);
      return prevState;
    });

    setFileNames(finalResult);

    deleteFileInCompliance(item, complinceList[index].complince_id);
    setOpen(false);
    setIsInfoUpdated(true);
  };

  const getSummaryData = async () => {
    if (Props.companyId) {
      let tocken = localStorage.getItem("@tocken");
      const AuthStr = "Bearer ".concat(tocken || "");
      //if current year ==> today's data else 31 Mar in that financial year
      let date =
        Props.financialYearGlobal === currentFY
          ? new Date(Date.now())
          : new Date(`${Props.financialYearGlobal + 1}-03-31`);
      const headers = {
        "Content-Type": "application/json",
        Authorization: AuthStr,
        // todayDate: new Date(Date.now()),
        todayDate: date,
        companyId: Props.companyId,
      };
      let apiUrl = `/complince/dashboard/summary`;
      let dashboardData = await Api.get(apiUrl, {
        headers,
      });
      if (dashboardData.data.status) {
        setDashboardData(dashboardData.data.data);
      }
    }
  };

  const getUserData = (_date: any) => {
    if (Props.companyId) {
      let dataList: any = [];
      let tocken = localStorage.getItem("@tocken");
      // let tocken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiN2M5Y2FhZTItYjlmYS00NTRlLWI0NjgtNWQxYmUwYzg1MDY1IiwicGFzc3dvcmQiOiIkMmIkMTAkQ2QuQktFaDQ0RUg0Nm9vNVZvck1OTzNVeEJ4a29jTGEuL1pycEt0ZThjLnlWbFdUc0t2ZFciLCJpYXQiOjE2NTUyMDk3NzEsImV4cCI6MTY2NTU3Nzc3MX0.rwrHXJ5Alr3LkzK8pJVi_wa3PS7iBjd6NBmeOdkkSLg'
      const AuthStr = "Bearer ".concat(tocken || "");
      const headers = {
        "Content-Type": "application/json",
        Authorization: AuthStr,
      };
      let dateRange = {
        end_date: `${Props.financialYearGlobal + 1}-03-30T18:29:59.999Z`,
        start_date: `${Props.financialYearGlobal}-03-31T18:29:59.999Z`,
        companyId: Props.companyId,
      };
      let _dRangeType = _date == null ? dateRange : _date;
      Api.post("complince/user/data", _dRangeType, {
        headers: headers,
      })
        .then((res) => {
          if (res.data.status) {
            if (_date != null) {
              res.data.data.forEach((e: any, i: number) => {
                e.status = ["Complete", "Pending"];
                e.dueDate = moment.utc(e.date).format("DD-MM-YYYY");
              });

              let requiredResult;

              setclanderInfo(res.data.data);

              requiredResult = res?.data?.data?.map((item: any, index: any) => {
                if (item.actions.length > 0) {
                  return item.actions[0];
                } else {
                  return {
                    complince_id: item.id,
                    // 'action':2,
                    docList: [],
                  };
                }
              });
              let files = requiredResult.map((item: any, id: any) => {
                let complianceId = item.complince_id;
                return item.docList.map((data: any) => {
                  data.complianceId = complianceId;

                  return data;
                });
              });
              var merged = [].concat.apply([], files);

              setFileNames(merged);
              // setImmutableFiles(merged)
              setIsInfoUpdated(false);
              setIsOpen(true);
              setComplinceList([...requiredResult]);
              //deep copy of 'setUnsaveComplinceList' - array of objects to prevent mutation
              setUnsaveComplinceList(
                JSON.parse(JSON.stringify(requiredResult))
              );
              setUnsavedDeletedFiles([]);
              return;
            }

            res.data.data.forEach((el: any) => {
              let resObject = {
                month: "",
                date: "",
                cType: undefined,
                complianceInfo: "",
                id: undefined,
                monthDate: "",
                isoDate: "",
                actions: "",
                isRemoved: false,
              };
              resObject.date = moment.utc(el.date).format("ll");
              resObject.monthDate = moment.utc(el.date).format("L");
              resObject.cType = el.comlince_type;
              resObject.complianceInfo = el.description;
              resObject.id = el.id;
              resObject.month = resObject.date.split(" ")[0];
              resObject.monthDate = resObject.monthDate.split("/")[1];
              resObject.isoDate = el.date;
              resObject.actions = el.actions?.[0]?.action;
              resObject.isRemoved = el.isRemoved;
              dataList.push(resObject);
            });

            months.forEach((monthItem, monthIndex) => {
              let dates = dataList.filter((x: any) => x.month === monthItem.m);

              // const uniqDateList: any = Array.from(
              //   dates.map((a: any) => a.monthDate)
              // ).map((id) => {
              //   return dates.find((a: any) => a.monthDate === id);
              // });

              const uniqDateList = dates;

              // console.log("cala dates: ", uniqDateList);

              if (uniqDateList.length) {
                months[monthIndex].date = uniqDateList;
              }
              monthItem.date.forEach((uniqueDate: any, dateIndex: number) => {
                let filteredListByDate = dataList.filter((data: any) => {
                  return data.date === uniqueDate.date;
                });
                // let actions: any[] = [];
                // filteredListByDate.forEach((date: any) => {
                //   actions.push(date.actions);
                // });
                // uniqueDate.actions = actions;
                //@ts-ignore
                months[monthIndex].date[dateIndex] = uniqueDate;
              });
            });
            setCalendarList(months);
          }
        })
        .catch((err) => {});
    }
  };

  const updateStatus = (data: any, status: any, index: any) => {
    if (status == "Complete") {
      setUnsaveComplinceList((prevState: any) =>
        prevState.map((item: any, i: any) => {
          if (i === index) {
            return { ...item, action: 2 };
          }
          return item;
        })
      );
    } else if (status == "Pending") {
      setUnsaveComplinceList((prevState: any) =>
        prevState.map((item: any, i: any) => {
          if (i === index) {
            return { ...item, action: undefined };
          }
          return item;
        })
      );
    } else if (status == "Not Applicable") {
      setUnsaveComplinceList((prevState: any) =>
        prevState.map((item: any, i: any) => {
          if (i === index) {
            return { ...item, action: 3 };
          }
          return item;
        })
      );
    }
  };

  const selectCompany = (evt: any) => {
    const companyId = evt.target.value;
    setCompany(companyId);
    manualDispatch(SetCompId(companyId));
    manualDispatch(FetchLogin(companyId));
  };

  const findValByKey = (arr: any[], key: string) => {
    let findKey = key ? key : arr;
    const value = arr?.filter((itemInArray) => itemInArray?.id == key);
    return value?.length > 0 ? value[0]?.id : "";
  };

  const fileUploadHandler = (event: any) => {
    setSelectedFile(event.target.files[0]);

    if (event.target.files[0]) {
      UploadFile(event.target.files[0]);
    }
  };
  const dateConvertion = (date: any) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return monthNames[new Date(date).getMonth()];
  };
  const triggerFileUpload = (index: any) => {
    ref.current.click();
    setSelectedIndex(index);
  };
  const guidGenerator = () => {
    var S4 = function () {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return S4() + S4();
  };
  const toBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const notifyFiles = () => toast.warning("Max 5 files allowed");
  const notifyFail = () => toast.warning("Error while uploading");

  const UploadFile = async (file: any) => {
    const fileSize = file.size / 1024 / 1024; // in MiB
    if (fileSize >= 5) {
      // alert('File size should be less than 5 MB');
      notifySize();
      // $(file).val(''); //for clearing with Jquery
    } else {
      // Proceed further
      if (complinceList[selectedIndex]?.docList.length >= 5) {
        return notifyFiles();
      } else {
        let tocken = localStorage.getItem("@tocken");
        let data;
        const AuthStr = "Bearer ".concat(tocken || "");
        const Headers = {
          "Content-Type": "application/json",
          Authorization: AuthStr,
        };
        let file_name = `${Props.first_name}-${
          file?.name?.split(".")[0]
        }${guidGenerator()}.${file?.name?.split(".")[1]}`;

        let year = new Date(financialYear + "").getFullYear() + 1;

        let filePath = `document/${
          Props.companyId
        }/${financialYear}-${year}/${dateConvertion(
          clanderInfo?.[selectedIndex].date
        )?.toLocaleLowerCase()}/${clanderInfo?.[
          selectedIndex
        ].comlince_type?.trim()}/${file_name}`;

        let reader = await toBase64(file);
        let fileData = reader;

        let displayName = file?.name?.split(".")[0];
        let obj = {
          fileName: file_name,
          displayName: file?.name?.split(".")[0],
          path: filePath,
          index: selectedIndex,
          complianceId: complinceList[selectedIndex]?.complince_id
            ? complinceList[selectedIndex]?.complince_id
            : complinceList[selectedIndex]?.complianceId,
        };

        data = {
          contentType: file.type,
          filePath,
          fileData,
        };

        let apiUrl = `${path()}/complince/uploadDocument`;
        manualDispatch(fileActions(true));
        try {
          const response = await fetch(apiUrl, {
            method: "POST",
            headers: Headers,
            body: JSON.stringify(data),
          });
          const res = await response.json();
          if (res.status) {
            setSelectedFile("");
            setFileNames([...fileNames, obj]);

            let saveDB = {
              fileName: file_name,
              displayName,
              s3Path: res.data.s3Path,
              s3url: res.data.s3Url,
            };
            manualDispatch(fileActions(false));

            let finalArr = [...complinceList][selectedIndex].docList.push(
              saveDB
            );
            setSaveToDB([...saveToDB, saveDB]);
            setComplinceList([...complinceList]);
            return;
          } else {
            manualDispatch(fileActions(false));
            notifyFail();
          }
        } catch (err) {
          manualDispatch(fileActions(false));
          notifyFail();
          console.log(err);
        }
      }
    }
  };

  const onSave = async () => {
    let tocken = localStorage.getItem("@tocken");
    let companyId = Props.companyId;
    const AuthStr = "Bearer ".concat(tocken || "");
    const Headers = {
      "Content-Type": "application/json",
      Authorization: AuthStr,
    };

    let data = {
      companyId,
      complianceList: complinceList,
    };
    try {
      const response = await fetch(`${path()}/complince/multiAction`, {
        method: "PATCH",
        headers: Headers,
        body: JSON.stringify(data),
      });
      const res = await response.json();
      if (res.status) {
        getSummaryData();
        setIsOpen(!isOpen);
        setFileNames([]);
        //to update the compliance completion tag status
        getUserData(null);
        return;
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  const download = (blob: any, filename: any) => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  const notify = () => toast.warning("No records found!");
  const notifySize = () => toast.warning("File size should be less than 5MB");

  const downloadYear = async (flag: any) => {
    let tocken = localStorage.getItem("@tocken");
    let companyId = Props.companyId;
    const AuthStr = "Bearer ".concat(tocken || "");
    const Headers = {
      "Content-Type": "application/json",

      Authorization: AuthStr,
    };
    let data;

    let year = `${financialYear}-${
      new Date(financialYear + "").getFullYear() + 1
    }`;
    let downloadFileName = `${year}${flag !== "year" ? "-" + flag + "-" : "-"}${
      Props.bussness_name
    }.zip`;
    if (flag == "year") {
      data = {
        companyId,
        year: year,
      };
    } else {
      data = {
        companyId,
        year: year,
        month: flag,
      };
    }
    manualDispatch(downloadActions(true));
    try {
      const response = await fetch(
        `${path()}/complince/download/documentList`,
        {
          method: "POST",
          headers: Headers,
          body: JSON.stringify(data),
        }
      );
      const res = await response.blob().then((blob) => {
        if (response.status == 204) {
          notify();
        } else {
          download(blob, downloadFileName);
        }
      });
      manualDispatch(downloadActions(false));
    } catch (err) {
      console.log(err);
      manualDispatch(downloadActions(false));
    }
  };

  const handleComplianceInfoClick = (
    event: any,
    complianceType: string,
    complianceInfo: string
  ) => {
    setAnchorEl(event.currentTarget);
    setComplianceInfo({ title: complianceType, content: complianceInfo });
  };

  const closeComplianceInfoPopper = () => {
    setAnchorEl(null);
  };

  const handleComplianceFormClose = () => {
    setOpenComplianceForm(false);
  };

  const createCompliance = async (formData: any, closeFormDialog: any) => {
    setLoading((loading) => ({ ...loading, createCompliance: true }));
    try {
      let tocken = localStorage.getItem("@tocken");
      const AuthStr = "Bearer ".concat(tocken || "");

      const {
        data: { status, message, data },
      } = await Api.post(
        "complince/addCompliance",
        { ...formData, companyId: Props.companyId },
        {
          headers: {
            Authorization: AuthStr,
          },
        }
      );

      if (status) {
        getUserData(null);
        getSummaryData();
        setTimeout(() => {
          toast.success(message);
          closeFormDialog();
        }, 1000);
      }
    } catch (error) {
      toast.error("Something went wrong while creating new compliance!");
    } finally {
      setTimeout(() => {
        setLoading((loading) => ({ ...loading, createCompliance: false }));
      }, 1000);
    }
  };

  const handleComplianceFormSubmit = (
    event: any,
    formData: object,
    closeFormDialog: any
  ) => {
    event?.preventDefault();
    console.log("form submitted!", formData);
    createCompliance(formData, closeFormDialog);
  };

  return (
    <div className="home-container">
      <Navbar history={Props.history} />
      <div className="main">
        <>
          {Props?.downloading ? (
            <div
              style={{
                height: "300px",
                zIndex: "1",
                position: "fixed",
                top: "50%",
                left: "47%",
                fontWeight: "600",
              }}
            >
              Downloading..
              <CircularProgress
                style={{
                  marginLeft: "8px",
                  height: "20px",
                  width: "20px",
                  color: "black",
                }}
              />
            </div>
          ) : (
            ""
          )}
        </>
        <ToastContainer position="top-right" autoClose={5000} />
        <div className="date-main__head dashboard-header">
          <div className="dashboard-header-left">
            {/* <p className="main__head-profile">DashBoard</p> */}
            <p className="main__head-name uname">
              Hi {Props.first_name?.toLowerCase()}{" "}
              {Props.last_name?.toLowerCase()}
              {/* {Props.last_name} ! */}
            </p>
          </div>
          <div className="dashboard-header-right">
            <Box sx={{ minWidth: 300 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select the name of company
                </InputLabel>

                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={companyName}
                  label="Select the name of company"
                  onChange={selectCompany}
                >
                  {Props?.user_data?.data?.companies?.map((company: any) => (
                    <MenuItem key={company.id} value={company.id}>
                      {company.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </div>

          {/* <div className="main__datetab">
            <div
              className={`monthtab ${index === 0 && "tabactive"}`}
              onClick={() => setIndex(0)}
            >
              Month
            </div>
            <div
              className={`daytab ${index === 1 && "tabactive"}`}
              onClick={() => setIndex(1)}
            >
              Day
            </div>
          </div> */}
        </div>

        <div className="company-name">{Props.bussness_name}</div>

        <div className="info-box">
          <div className="info-iner-box">
            <div className="info-icon rec-icon">
              <Receipt />
            </div>
            <div className="info-detail">
              Total Compliances applicable in the year
            </div>
            <div className="info-number number-color">
              {dashboardData.totalCompliance}
            </div>
          </div>
          <div className="info-iner-box">
            <div className="info-icon clip-icon">
              <Clipboard />
            </div>
            <div className="info-detail">Compliances applicable till date</div>
            <div className="info-number number-color">
              {dashboardData.totalComplianceApplicableTillDate}
            </div>
          </div>
          <div className="info-iner-box">
            <div className="info-icon tick-icon">
              <Checkcircle />
            </div>
            <div className="info-detail">Compliances completed till date</div>
            <div className="info-number number-color">
              {dashboardData.complianceCompletedTillDate}
            </div>
          </div>
          <div className="info-iner-box">
            <div className="info-icon arrow-icon">
              <JournalArrow />
            </div>
            <div className="info-detail">
              Penalty on non-compliaces overdue (approx)
            </div>
            <div className="info-number rs-number">
              Rs {dashboardData.penalty}
            </div>
          </div>
          <div className="info-iner-box">
            <div className="info-icon eye-icon">
              <Bullseye />
            </div>
            <div className="info-detail">Your compliance Score</div>
            <div className="info-number number-color">
              {dashboardData.displayScore}
            </div>
          </div>
        </div>

        <div className="company-calender-year">
          <div className="finance-year-text">
            Compliance Calendar for Financial Year
          </div>
          <Box sx={{ minWidth: 300 }} className="select-year">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Select Year</InputLabel>

              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={Props.financialYearGlobal}
                label="Select Year"
                onChange={(event: any) => {
                  setFinancialYear(event.target.value);
                  localStorage.setItem("fyear", event.target.value);
                }}
              >
                {fYearData.map((year) => {
                  return (
                    <MenuItem key={year} value={year}>
                      Apr {year} - Mar {year + 1}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <Button
            className={`download-btn`}
            style={{ pointerEvents: Props?.downloading && "none" }}
            onClick={() => downloadYear("year")}
          >
            Download documents{" "}
          </Button>
        </div>

        <div className="calender-section" id="1">
          {calendarList?.map((monthData: any, monthDataIndex: number) => {
            // console.log("monthData", monthData);
            return (
              <Fragment key={monthData.m}>
                {monthData.date.length >= 0 && (
                  <div className="calender-box" id={monthData.m}>
                    <div className="calender-header">
                      <div className="clander-month">{monthData.m}</div>
                      <div
                        className="clander-download-btn"
                        style={{ pointerEvents: Props?.downloading && "none" }}
                        onClick={() => downloadYear(monthData.full)}
                      >
                        Download{" "}
                      </div>
                    </div>
                    <div className="calander-info">
                      {monthData.date.map(
                        (calinfo: any, calInfoIndex: number) => {
                          // console.log("calinfo", calinfo);
                          if (calinfo.isRemoved) return;
                          return (
                            <Fragment key={calinfo.id}>
                              <div
                                className="calnder-info-iner"
                                key={calinfo.id}
                              >
                                <div className="calnder-date">
                                  {calinfo.monthDate}
                                </div>
                                <TextButton
                                  id="calnder-text"
                                  sx={{
                                    textTransform: "capitalize",
                                    textAlign: "left",
                                  }}
                                  onClick={() => infoData(calinfo)}
                                >
                                  {calinfo.cType}
                                </TextButton>
                                <Box
                                  sx={{
                                    ml: "auto",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {calinfo.actions === undefined ? (
                                    <CircleTwoToneIcon
                                      color="error"
                                      fontSize="small"
                                    />
                                  ) : calinfo.actions === 2 ? (
                                    <CheckCircleTwoToneIcon
                                      color="success"
                                      fontSize="small"
                                    />
                                  ) : (
                                    <CircleTwoToneIcon
                                      color="disabled"
                                      fontSize="small"
                                    />
                                  )}
                                </Box>
                              </div>
                            </Fragment>
                          );
                        }
                      )}
                      <TextButton
                        fullWidth
                        startIcon={<AddCircle />}
                        sx={{ ml: "auto" }}
                        variant="text"
                        onClick={() => setOpenComplianceForm(true)}
                      >
                        Create Compliance
                      </TextButton>
                    </div>
                  </div>
                )}
              </Fragment>
            );
          })}
        </div>
      </div>
      <ComplianceInfoPopper
        anchorEl={anchorEl}
        complianceInfo={complianceInfo}
        closePopper={closeComplianceInfoPopper}
      />
      <ComplianceEditorDialog
        open={openComplianceForm}
        onClose={handleComplianceFormClose}
        onSubmit={handleComplianceFormSubmit}
        loading={loading.createCompliance}
      />

      <Custompopup
        open={isOpen}
        handleCancelClick={() => {
          //if no information is updated; close big compliance pop up
          if (!isInfoUpdated) {
            //compliance pop-up closed
            setIsOpen(false);
          }
          //Cancel pop up to open - based on whether information is updated or not
          setCancelOpen(isInfoUpdated);
        }}
        onSave={async () => {
          setComplinceList((prevState: any) =>
            prevState.map((item: any, index: any) => {
              item.action = unsaveComplinceList[index].action;
              return item;
            })
          );
          await deleteFromS3(unsavedDeletedFiles);

          onSave();
        }}
        isLoading={Props.file_action}
        content={
          <>
            <div className="cander-header">
              <div className="clander-header-inr right-line clander-hedding">
                Due Date
              </div>
              <div className="clander-header-inr right-line clander-hedding">
                Type
              </div>
              <div className="clander-header-inr right-line clander-hedding">
                Details
              </div>
              <div className="clander-header-inr right-line clander-hedding">
                Penalty
              </div>
              <div className="clander-header-inr right-line clander-hedding">
                Status
              </div>
              <input
                ref={ref}
                type="file"
                style={{ display: "none" }}
                onChange={(evt: any) => {
                  fileUploadHandler(evt);
                  setIsInfoUpdated(true);
                }}
                onClick={(evt: any) => {
                  evt.target.value = null;
                }}
              />

              <div className="clander-header-inr right-line clander-hedding">
                Attachment
              </div>
            </div>
            {clanderInfo?.map((clanderdata: any, index: any) => {
              return (
                <Fragment key={clanderdata?.dueDate}>
                  <div className="cander-body">
                    <div className="clander-body-inr">
                      {clanderdata?.dueDate}
                    </div>
                    <div className="clander-body-inr">
                      {clanderdata?.comlince_type}
                    </div>
                    <div className="clander-body-inr">
                      {clanderdata?.comlince}{" "}
                      {clanderdata?.description && (
                        <Button
                          variant="text"
                          onClick={(event) =>
                            handleComplianceInfoClick(
                              event,
                              clanderdata?.comlince_type,
                              clanderdata?.description
                            )
                          }
                          style={{
                            color: "dodgerBlue",
                            textTransform: "lowercase",
                            padding: 0,
                          }}
                        >
                          read more
                        </Button>
                      )}
                    </div>
                    <div className="clander-body-inr">
                      {clanderdata?.intrest}
                    </div>
                    <div className="clander-body-inr">
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          onChange={(event) => {
                            let status = event.target.value; // 'Complete' or 'Pending'
                            updateStatus(clanderdata, status, index);
                            setIsInfoUpdated(true);
                          }}
                          defaultValue={
                            clanderdata?.actions[0]?.action === 2
                              ? "Complete"
                              : clanderdata?.actions[0]?.action === 3
                              ? "Not Applicable"
                              : "Pending"
                          }
                        >
                          <FormControlLabel
                            value="Complete"
                            control={<Radio className="completed" />}
                            label="Complete"
                          />
                          {/* {!clanderdata?.actions[0]?.action && ( */}
                          <FormControlLabel
                            value="Pending"
                            control={<Radio className="pending" />}
                            label="Pending"
                          />
                          <FormControlLabel
                            value="Not Applicable"
                            control={<Radio className="na" />}
                            label="Not Applicable"
                          />
                          {/* )} */}
                        </RadioGroup>
                      </FormControl>
                    </div>
                    <div className="clander-body-inr">
                      <Stack
                        direction="row"
                        spacing={1}
                        className="clander-spacce"
                        style={{
                          textOverflow: "ellipsis",
                          maxWidth: "110px",
                        }}
                      >
                        {fileNames.map((item: any, id: any) => {
                          if (item.complianceId == clanderdata.id) {
                            return (
                              <Fragment key={item.complianceId}>
                                <Chip
                                  label={
                                    item.displayName
                                      ? item.displayName
                                      : item.fileName
                                  }
                                  variant="outlined"
                                  onDelete={() => handleOpen(item, index)}
                                  className="chip-btn"
                                />
                              </Fragment>
                            );
                          }
                        })}
                      </Stack>
                      <Dialog open={open} onClose={handleClose}>
                        <DialogTitle>Delete Uploaded file?</DialogTitle>
                        <DialogContent>
                          <DialogContentText>
                            Please note this file cannot be retrived again.
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Button
                                style={{
                                  backgroundColor: "#FFFFFF",
                                  color: "black",
                                  width: "100%",
                                }}
                                variant="contained"
                                onClick={handleClose}
                              >
                                DON'T DELETE
                              </Button>
                            </Grid>
                            <Grid item xs={6}>
                              <Button
                                style={{
                                  backgroundColor: "#EF5350",
                                  width: "100%",
                                }}
                                variant="contained"
                                onClick={() => {
                                  handleDeleteClick(
                                    deletingAttachment,
                                    attachmentIndex
                                  );
                                }}
                              >
                                DELETE
                              </Button>
                            </Grid>
                          </Grid>
                        </DialogActions>
                      </Dialog>
                      <Button
                        onClick={() => triggerFileUpload(index)}
                        autoFocus
                        className="attched-btn"
                        style={{ pointerEvents: Props.file_action && "none" }}
                      >
                        Add attachment
                      </Button>
                    </div>
                  </div>
                </Fragment>
              );
            })}
          </>
        }
      />
      <Dialog open={cancelOpen} onClose={handleCancelClose} maxWidth={"xs"}>
        <DialogTitle>Are you sure you want to Cancel?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please note all the changes you made will not be saved.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                style={{
                  backgroundColor: "#FFFFFF",
                  color: "black",
                  width: "100%",
                }}
                variant="contained"
                onClick={handleCancelClose}
              >
                BACK
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                style={{ backgroundColor: "#EF5350", width: "100%" }}
                variant="contained"
                onClick={deleteFilesOnCancel}
              >
                DON'T SAVE
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  first_name: state.user.first_name,
  last_name: state.user.last_name,
  bussness_name: state.user.bussness_name,
  user_data: state.user.user_data,
  companyId: state.user.companyId,
  financialYearGlobal: state.user.financialYear,
  file_action: state.user?.isLoading,
  downloading: state.user?.isDownLoading,
  user: state.user,
});
const mapDispatchToProps = (dispatch: any) => ({
  // startAction: () => dispatch(startAction),
  // stopAction: () => dispatch(stopAction)
  // srtCompId: (data: string) => dispatch(SetCompId(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
